import React from 'react';
import './index.scss';
import { SwitchTool } from '@ccm-innovation/switchtool-components';
import * as MockOptions from './mock-data';

const SwitchToolTiledDemo = (props) => {
  const [language, setLanguage] = React.useState('en-US');
  const [accountId, setAccountId] = React.useState('515117893');
  const [data, setData] = React.useState(MockOptions.switchOptions1);
  const [showHealthDiscount, setShowHealthDiscount] = React.useState(false);

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };

  const handleChangeShowDiscount = (event) => {
    setShowHealthDiscount(event.target.checked);
  };

  const handleChangeAccountId = (event) => {
    setAccountId(event.target.value);

    let switchOptionsData = null;
    switch (event.target.value) {
      case '515117893':
        switchOptionsData = MockOptions.switchOptions1;
        break;
      case '515131332':
        switchOptionsData = MockOptions.switchOptions2;
        break;
      case '515140267':
        switchOptionsData = MockOptions.switchOptions3;
        break;
      case '515140614':
        switchOptionsData = MockOptions.switchOptions4;
        break;
      case '513829531':
        switchOptionsData = MockOptions.switchOptions5;
        break;
      case '515121572':
        switchOptionsData = MockOptions.switchOptions6;
        break;
      case '515144913':
        switchOptionsData = MockOptions.switchOptions7;
        break;
      case '515140705':
        switchOptionsData = MockOptions.switchOptions8;
        break;
      default:
        break;
    }

    console.log(switchOptionsData);
    setData(switchOptionsData);
  };

  return (
    <div className="switch-tool-tiled-demo">
      <h1>Switch Tool</h1>
      <div className="switch-tool-tiled-demo__props">
        <div>
          <label
            className="switch-tool-tiled-demo__props-label"
            htmlFor="language"
          >
            Language:
          </label>
          <select
            className="switch-tool-tiled-demo__props-control"
            id="language"
            value={language}
            onChange={handleChangeLanguage}
          >
            <option value="en-US">English</option>
            <option value="es-US">Española</option>
            <option value="ps-PS">Not Localized</option>
          </select>
        </div>
        <div>
          <label
            className="switch-tool-tiled-demo__props-label"
            htmlFor="showHealthDiscount"
          >
            Show Discount:
          </label>
          <input
            type="checkbox"
            className="switch-tool-tiled-demo__props-control"
            id="showHealthDiscount"
            checked={showHealthDiscount}
            onChange={handleChangeShowDiscount}
          />
        </div>
        <div>
          <label
            className="switch-tool-tiled-demo__props-label"
            htmlFor="accountId"
          >
            Account ID:
          </label>
          <select
            className="switch-tool-tiled-demo__props-control"
            id="language"
            value={accountId}
            onChange={handleChangeAccountId}
          >
            <option value="515117893">515117893 - MS2</option>
            <option value="515131332">515131332 - MS3-3K</option>
            <option value="515140267">515140267 - MS3-3K</option>
            <option value="515140614">515140614 - MS3-6K</option>
            <option value="513829531">513829531 - MS3-6K Minor</option>
            <option value="515121572">515121572 - MS3-9K</option>
            <option value="515144913">515144913 - MS3-12K</option>
            <option value="515140705">515140705 - MS3-12K Family</option>
          </select>
        </div>
      </div>
      <hr />
      <div className="switch-tool-tiled-demo__component">
        <SwitchTool
          showHealthDiscount={showHealthDiscount}
          language={language}
          accountId={accountId}
          maritalStatus="Married"
          onConfirmSwitchRequest={(data) => console.dir(data)}
          onCancelSwitchRequest={() => console.dir('Cancelling')}
          data={data}
          rootElementSelector="#root"
        />
      </div>
    </div>
  );
};

export default SwitchToolTiledDemo;
