import React from 'react'
import { Link } from 'react-router-dom';

import "./index.scss";

const MemberAssetComponentsAbout = () => (
  <div className="home">
    <h2>Member Asset Components</h2>

    <hr/>
    
    <p className="component-list-description">
      This website showcases CCM's library components related to all things member assets that are consumable by our suite of products. <br/>
      The following components are available for demo purposes:
    </p>
    
    <ComponentNaviagtionList />
  </div>
)

const ComponentNaviagtionList = () => (
  <div className="component-list">
    <ul>
      <li>
        <Link to="member-asset-components/medical-spend-history">Medical Spend History</Link> - Displays the household's current and past medical spending history
      </li>
      <li>
        <Link to="member-asset-components/member-cards" >Member Cards</Link> - Displays member cards given a household plan info payload
      </li>
    </ul>
  </div>
)

export default MemberAssetComponentsAbout;
