import React, { useState } from 'react';
import { MedicalSpendHistory } from '@ccm-innovation/member-center-react-controls';
import './index.scss';
import * as MockData from './mock-data';

const MedicalSpendHistoryDemo = () => {
  const [sampleDataSelected, setSampleDataSelected] = useState('standard');
  const [sampleData, setSampleData] = useState(
    MockData.standardBillsDataExample
  );

  const handleChangeSampleData = (val) => {
    val === 'co-share'
      ? setSampleData(MockData.coShareBillsDataExample)
      : setSampleData(MockData.standardBillsDataExample);

    setSampleDataSelected(val);
  };

  return (
    <div className={'msh-demo'}>
      <div className="msh-demo__props">
        <div>
          <label className="msh-demo__props-label" htmlFor="msh-sample-data">
            Sample Data:
          </label>
          <select
            className="msh-demo__props-control"
            id="msh-sample-data"
            value={sampleDataSelected}
            onChange={(e) => handleChangeSampleData(e.target.value)}
          >
            <option value={'standard'}>Standard</option>
            <option value={'co-share'}>Co-Share</option>
          </select>
        </div>
      </div>
      <hr />
      <h2>Medical Spend History</h2>
      <div className="msh-demo__component">
        <MedicalSpendHistory
          billsData={sampleData}
          totalsData={MockData.totalsData}
          onDownloadEOS={(data) => {
            console.log(data);
            alert(`DOWNLOADING EOS for Claim ID: ${data.claimId}`);
          }}
        />
      </div>
    </div>
  );
};

export default MedicalSpendHistoryDemo;
