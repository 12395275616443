import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss';

const Home = () => (
  <div className="home">
    <h2>CCM Innovation</h2>

    <hr />

    <p className="component-list-description">
      This website showcases CCM's library of components that are consumable by
      our suite of products. The following components are available for demo
      purposes.
    </p>

    <ComponentNaviagtionList />
  </div>
);

const ComponentNaviagtionList = () => (
  <div className="component-list">
    <ul>
      <li>
        <Link to="address-components">Address Components</Link>
        <ul>
          <li>
            <Link to="address-components/state-picker">State Picker</Link> -
            Noramlized state input
          </li>
          <li>
            <Link to="address-components/address-picker">Address Picker</Link> -
            Noramlized address input
          </li>
        </ul>
      </li>
      <li>
        <Link to="member-asset-components">Member Asset Components</Link>
        <ul>
          <li>
            <Link to="member-asset-components/medical-spend-history">
              Medical Spend History
            </Link>{' '}
            - Displays the household's current and past medical spending history
          </li>
          <li>
            <Link to="member-asset-components/member-cards">Member Cards</Link>{' '}
            - Displays member cards given a household plan info payload
          </li>
        </ul>
      </li>
      <li>
        <Link to="member-center-react-controls">
          Member Center React Controls
        </Link>
        <ul>
          <li>
            <Link to="member-center-react-controls/medical-spend-history">
              Medical Spend History
            </Link>{' '}
            - Displays the household's current and past medical spending history
          </li>
        </ul>
      </li>
      <li>
        <Link to="pricing-components">Pricing Components</Link>
        <ul>
          <li>
            <Link to="pricing-components/claims-dashboard">
              Claims Dashboard
            </Link>{' '}
            - MVP version of the component used to display the member's claims
            information
          </li>
          <li>
            <Link to="pricing-components/pricing-calculator">
              Pricing Calculator
            </Link>{' '}
            - Easy to use pricing calculator to estimate program options
          </li>
          <li>
            <Link to="pricing-components/pricing-calculator-classic">
              Pricing Calculator Classic
            </Link>{' '}
            - Classic pricing calculator to estimate program options
          </li>
          <li>
            <Link to="pricing-components/pricing-calculator-tiled">
              Pricing Calculator Tiled
            </Link>{' '}
            - Tiled pricing calculator to estimate program options
          </li>
          <li>
            <Link to="pricing-components/pricing-donut">Pricing Donut</Link> -
            Pricing Donut chart that displays a set of specified data passed to
            it.
          </li>
          <li>
            <Link to="pricing-components/switch-tool-tiled">
              Switch Tool Tiled
            </Link>{' '}
            - Tiled switch tool to estimate program options
          </li>
          <li>
            <Link to="pricing-components/pricing-table-classic">
              Pricing Table Classic
            </Link>{' '}
            - Classic pricing table to display program options
          </li>
        </ul>
      </li>
    </ul>
  </div>
);

export default Home;
