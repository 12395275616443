import React from 'react'
import { Link } from 'react-router-dom';

import "./index.scss";

const AddressComponentsAbout = () => (
  <div className="home">
    <h2>Address Components</h2>

    <hr/>
    
    <p className="component-list-description">
      This website showcases CCM's library of address related components that are consumable by our suite of products. <br/>
      The following components are available for demo purposes:
    </p>
    
    <ComponentNaviagtionList />
  </div>
)

const ComponentNaviagtionList = () => (
  <div className="component-list">
    <ul>
    <li>
      <Link to="address-components/state-picker" >State Picker</Link> - Noramlized state input
    </li>
    <li>
      <Link to="address-components/address-picker" >Address Picker</Link> - Noramlized address input
    </li>
    </ul>
  </div>
)

export default AddressComponentsAbout;
