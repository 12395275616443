import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss';

const MemberCenterReactControlsAbout = () => (
  <div className="home">
    <h2>Member Center React Controls</h2>

    <hr />

    <p className="component-list-description">
      This website showcases CCM's library components that are consumed by our
      Member Center application(s). <br />
      <br />
      The following components are available for demo purposes:
    </p>

    <ComponentNaviagtionList />
  </div>
);

const ComponentNaviagtionList = () => (
  <div className="component-list">
    <ul>
      <li>
        <Link to="member-center-react-controls/medical-spend-history">
          Medical Spend History
        </Link>{' '}
        - Displays the household's current and past medical spending history
      </li>
    </ul>
  </div>
);

export default MemberCenterReactControlsAbout;
