import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss';

const PricingComponentsAbout = () => (
  <div className="home">
    <h2>Pricing Components</h2>

    <hr />

    <p className="component-list-description">
      This website showcases CCM's library of address realted components that
      are consumable by our suite of products. <br />
      <br />
      The following components are available for demo purposes:
    </p>

    <ComponentNaviagtionList />
  </div>
);

const ComponentNaviagtionList = () => (
  <div className="component-list">
    <ul>
      <li>
        <Link to="pricing-components/claims-dashboard">Claims Dashboard</Link> -
        MVP version of the component used to display the member's claims
        information
      </li>
      <li>
        <Link to="pricing-components/pricing-calculator">
          Pricing Calculator
        </Link>{' '}
        - Easy to use pricing calculator to estimate program options
      </li>
      <li>
        <Link to="pricing-components/pricing-calculator-classic">
          Pricing Calculator Classic
        </Link>{' '}
        - Classic pricing calculator to estimate program options
      </li>
      <li>
        <Link to="pricing-components/pricing-calculator-tiled">
          Pricing Calculator Tiled
        </Link>{' '}
        - Tiled pricing calculator to estimate program options
      </li>
      <li>
        <Link to="pricing-components/pricing-donut">Pricing Donut</Link> -
        Pricing Donut chart that displays a set of specified data passed to it.
      </li>
      <li>
        <Link to="pricing-components/switch-tool-tiled">Switch Tool Tiled</Link>{' '}
        - Tiled switch tool to estimate program options
      </li>
      <li>
        <Link to="pricing-components/pricing-table-classic">
          Pricing Table Classic
        </Link>{' '}
        - Classic pricing table to display program options
      </li>
    </ul>
  </div>
);

export default PricingComponentsAbout;
