import React, { useEffect } from 'react'
import {
  Grid,
  Row,
  Col,
  Button,
  Animation,
  Form,
  FormGroup,
  Toggle,
  ControlLabel
} from 'rsuite'
import './index.scss'
import { PricingCalculatorClassic } from '@ccm-innovation/pricing-components';
const { Collapse } = Animation;

const PricingCalculatorClassicDemo  = props => {
  const initialState = {
    // Show Customization Section Toggle
    showCustomizationSection: false,
    // Props
    showFullStateNames: true,
    greenLabels: false,
    debugMode: false,
    // Results
    planOptionsRequested: false,
    planOptions: null,
    // Miscellaneous
    componentMounted: true,
  }

  const [componentMounted, setComponentMounted] = React.useState(initialState.componentMounted);
  useEffect(() => {
    if (!componentMounted) {
      setComponentMounted(true);
    }
  }, [componentMounted]);

  const [showCustomizationSection, setShowCustomizationSection] = React.useState(initialState.showCustomizationSection);
  const [showFullStateNames, setShowFullStateNames] = React.useState(initialState.showFullStateNames);
  const [greenLabels, setGreenLabels] = React.useState(initialState.greenLabels);
  const [planOptions, setPlanOptions] = React.useState(initialState.planOptions);
  const [showPlanOptionsRequested, setShowPlanOptionsRequested] = React.useState(initialState.planOptionsRequested);

  const [debugMode, setDebugMode] = React.useState(initialState.debugMode);
  useEffect(() => {
    setComponentMounted(false);
  }, [debugMode]);

  const resetState = () => {
    setComponentMounted(false);
    setShowFullStateNames(initialState.showFullStateNames);
    setGreenLabels(initialState.greenLabels);
    setPlanOptions(initialState.planOptions);
    setShowPlanOptionsRequested(initialState.setPlanOptionsRequested);
  }

  const handlePlanOptionsRequested = () => {
    setShowPlanOptionsRequested(true);
    setPlanOptions(initialState.planOptions);
  }

  const handlePlanOptionsFetched = (options) => {
    console.log('Plan Options Fetched:', options);
    console.log(JSON.stringify(options));
    setPlanOptions(options);
    setShowPlanOptionsRequested(false);
  }

  return (
    <div className='pricing-calculator-classic-demo-wrapper'>
      <Grid className='pricing-calculator-classic-main-grid'>
        <Row>
          <h1>Pricing Calculator Classic</h1>
        </Row>

        <br />

        <Row>
          <Col xs={12}>
            <Button
              color="green"
              onClick={() => setShowCustomizationSection(!showCustomizationSection)}>
              {showCustomizationSection ? 'Hide' : 'Show'} Customization Section
            </Button>
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Form layout="inline" className="pricing-calculator-classic-customization-form">
              <FormGroup>
                <Toggle
                  checked={debugMode}
                  onChange={checked => setDebugMode(checked) } />
                <ControlLabel className="pricing-calculator-classic-inline-toggle-label">Debug Mode</ControlLabel>
              </FormGroup>
            </Form>
          </Col>
        </Row>

        <Row>
          <Collapse in={showCustomizationSection}>
            <div className="pricing-calculator-classic-options" >
              <Row>
                <Col>
                  <h2>Customization Options</h2>
                </Col>
              </Row>
              <Row>
                <Form layout="inline" className="pricing-calculator-classic-customization-form">
                  <FormGroup>
                    <Toggle
                      checked={showFullStateNames}
                      onChange={checked => setShowFullStateNames(checked) } />
                    <ControlLabel className="pricing-calculator-classic-inline-toggle-label">Show Full State Names</ControlLabel>
                  </FormGroup>
                </Form>
                <Form layout="inline" className="pricing-calculator-classic-customization-form">
                  <FormGroup>
                    <Toggle
                      checked={greenLabels}
                      onChange={checked => setGreenLabels(checked) } />
                    <ControlLabel className="pricing-calculator-classic-inline-toggle-label">Green Labels</ControlLabel>
                  </FormGroup>
                </Form>
              </Row>
              <Row>
                <Button
                  appearance="primary"
                  onClick={() => resetState()} >
                  Reset Component
                </Button>
              </Row>
            </div>
          </Collapse>
        </Row>
        
        <hr />
        
        <Row>
          <Col className='pricing-calculator-classic-component'>
            {
              componentMounted &&
              <PricingCalculatorClassic
                muleApiUrl={process.env.REACT_APP_MULE_API_URL}
                muleClientId={process.env.REACT_APP_MULE_API_CLIENT_ID}
                muleClientSecret={process.env.REACT_APP_MULE_API_CLIENT_SECRET}
                onPlanOptionsRequested={() => handlePlanOptionsRequested()}
                onPlanOptionsFetched={options => handlePlanOptionsFetched(options)}
                onStatesFetched={states => {
                  debugMode && console.log('States:', states)
                }}
                showFullStateNames={showFullStateNames}
                greenLabels={greenLabels}
                debugMode={debugMode}
              />
            }
          </Col>
        </Row>

        <Row>
          <Col>
            {
              showPlanOptionsRequested &&
              <h3>Plan Options Requested</h3>
            }
            {
              planOptions &&
              <div>
                <h3>Plan Options</h3>
                <div>{JSON.stringify(planOptions)}</div>
              </div>
            }
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default PricingCalculatorClassicDemo;
