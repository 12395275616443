import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Button,
  Toggle,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Animation,
  SelectPicker,
} from "rsuite";
import * as _ from "lodash";
import { AddressPicker } from "@ccm-innovation/address-components";

import "./index.scss";

const { Collapse } = Animation;

export default class CCMAddressDemo extends Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(JSON.stringify(initialState));
  }

  onAddressCollected(address) {
    this.setState({
      displayInfo: true,
      addressCollected: address,
      addressValidated: true,
      generalInputStyle: "",
    });
  }

  onAddressInvalidated() {
    this.setState({
      addressValidated: false,
      addressCollected: null,
      generalInputStyle: "override-input-style",
    });
  }

  updateOptionChange(property, value) {
    let currentStateNewOptions = this.state.newOptions;
    currentStateNewOptions[property] = value;

    if (property === "passAddressToComponent" && value === false) {
      currentStateNewOptions["checkForValidAddressUponMount"] = false;
      currentStateNewOptions["address"] = initialState.options.address;
    }

    this.setState({ newOptions: currentStateNewOptions });
  }

  handleRefreshComponent() {
    const copyOfNewOptions = JSON.parse(JSON.stringify(this.state.newOptions));
    this.setState(
      {
        displayInfo: false,
        addressValidated: null,
        addressCollected: null,
        generalInputStyle: "",
        options: copyOfNewOptions,
        refreshingComponent: true,
      },
      () => {
        this.setState({ refreshingComponent: false });
        this.setTemporaryMessage("Component Refreshed");
      }
    );
  }

  updateAddressToPass(property, value) {
    let currentStateNewOptions = this.state.newOptions;
    let currentStateNewOptionsAddress = currentStateNewOptions.address;

    currentStateNewOptionsAddress[property] = value;

    if (this.checkIfAddressHasChanged(currentStateNewOptionsAddress) === false)
      currentStateNewOptions["checkForValidAddressUponMount"] = false;

    this.setState({
      newOptions: currentStateNewOptions,
    });
  }

  handleClearAddress() {
    let currentStateNewOptions = this.state.newOptions;
    this.setState({
      newOptions: {
        ...currentStateNewOptions,
        address: JSON.parse(JSON.stringify(initialState.options.address)),
      },
    });
  }

  checkIfAddressHasChanged(address) {
    if (
      address.line1 !== "" &&
      address.city !== "" &&
      address.state !== "" &&
      address.zip !== ""
    ) {
      return true;
    }

    return false;
  }

  handleResetOptions() {
    this.setState(
      {
        refreshingComponent: true,
        options: JSON.parse(JSON.stringify(initialState.options)),
        newOptions: JSON.parse(JSON.stringify(initialState.newOptions)),
      },
      () => this.setState({ refreshingComponent: false })
    );

    this.setTemporaryMessage("Options Reset + Component Refreshed");
  }

  toggleCustomizationSection() {
    this.setState({
      showCustomizationSection: !this.state.showCustomizationSection,
    });
  }

  setTemporaryMessage(message) {
    this.setState(
      {
        temporaryMessage: message,
      },
      () => setTimeout(() => this.setState({ temporaryMessage: "" }), 3000)
    );
  }

  render() {
    const {
      refreshingComponent,
      displayInfo,
      addressCollected,
      addressValidated,
      fetchedStates,
      generalInputStyle,
      options,
      newOptions,
      showCustomizationSection,
      temporaryMessage,
    } = this.state;

    //  Optional Props
    const styleOverride = {
      mainWidth: "override-main-width",
      modalWidth: "override-modal-width",
      borderRadius: "override-border-radius",
      generalInputStyle,
      requiredAsterisk: "override-required-asterisk",
      line1InputStyle: "override-line1-input",
      cityInputStyle: "override-city-input",
      stateInputStyle: "override-state-input",
      zipInputStyle: "override-zip-input",
    };

    const standardStyle = {
      generalInputStyle,
    };

    return (
      <div className="CCMAddressWrapper">
        <h1>Address Picker</h1>
        <Grid className="ccm-address-main-grid">
          <Row>
            <Button
              color="green"
              onClick={() => this.toggleCustomizationSection()}
            >
              {showCustomizationSection ? "Hide" : "Show"} Customization Section
            </Button>
          </Row>
          <hr />
          <Collapse in={showCustomizationSection}>
            <div className="ccm-address-options">
              <Row>
                <Col>
                  {showCustomizationSection && <h2>Customization Options</h2>}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form
                    layout="inline"
                    className="ccm-address-customization-form"
                  >
                    <FormGroup>
                      <Toggle
                        checked={newOptions.componentDisabled}
                        onChange={(checked) =>
                          this.updateOptionChange("componentDisabled", checked)
                        }
                      />
                      <ControlLabel className="inline-toggle-label">
                        Component Disabled
                      </ControlLabel>
                    </FormGroup>
                  </Form>
                  <Form
                    layout="inline"
                    className="ccm-address-customization-form"
                  >
                    <FormGroup>
                      <Toggle
                        checked={newOptions.includeGeocodeData}
                        onChange={(checked) =>
                          this.updateOptionChange("includeGeocodeData", checked)
                        }
                      />
                      <ControlLabel className="inline-toggle-label">
                        Include Geocode Data
                      </ControlLabel>
                    </FormGroup>
                  </Form>

                  <Form
                    layout="inline"
                    className="ccm-address-customization-form"
                  >
                    <FormGroup>
                      <Toggle
                        checked={newOptions.applyCustomStyle}
                        onChange={(checked) =>
                          this.updateOptionChange("applyCustomStyle", checked)
                        }
                      />
                      <ControlLabel className="inline-toggle-label">
                        Apply Custom Style
                      </ControlLabel>
                    </FormGroup>
                  </Form>

                  <Form
                    layout="inline"
                    className="ccm-address-customization-form"
                  >
                    <FormGroup>
                      <Toggle
                        checked={newOptions.showProcessingMessages}
                        onChange={(checked) =>
                          this.updateOptionChange(
                            "showProcessingMessages",
                            checked
                          )
                        }
                      />
                      <ControlLabel className="inline-toggle-label">
                        Show Processing Messages
                      </ControlLabel>
                    </FormGroup>
                  </Form>

                  <Form
                    layout="inline"
                    className="ccm-address-customization-form"
                  >
                    <FormGroup>
                      <Toggle
                        checked={newOptions.showRequiredAsterisks}
                        onChange={(checked) =>
                          this.updateOptionChange(
                            "showRequiredAsterisks",
                            checked
                          )
                        }
                      />
                      <ControlLabel className="inline-toggle-label">
                        Show Required Asterisks
                      </ControlLabel>
                    </FormGroup>
                  </Form>

                  <Form
                    layout="inline"
                    className="ccm-address-customization-form"
                  >
                    <FormGroup>
                      <Toggle
                        checked={newOptions.displayCustomTitle}
                        onChange={(checked) =>
                          this.updateOptionChange("displayCustomTitle", checked)
                        }
                      />
                      <ControlLabel className="inline-toggle-label">
                        Display Custom Component Title
                      </ControlLabel>
                    </FormGroup>
                  </Form>

                  <Form
                    layout="inline"
                    className="ccm-address-customization-form"
                  >
                    <FormGroup>
                      <ControlLabel
                        style={
                          !newOptions.displayCustomTitle
                            ? { color: "lightgray" }
                            : {}
                        }
                      >
                        Custom Title
                      </ControlLabel>
                      <FormControl
                        disabled={!newOptions.displayCustomTitle}
                        value={newOptions.customTitle}
                        onChange={(value) =>
                          this.updateOptionChange("customTitle", value)
                        }
                        name="customTitle"
                      />
                    </FormGroup>
                  </Form>
                </Col>

                <Col xs={12}>
                  <FormGroup>
                    <ControlLabel className="address-type-label">
                      Address Type:
                    </ControlLabel>
                    <SelectPicker
                      data={[
                        { value: "home", label: "Home" },
                        { value: "mailing", label: "Mailing" },
                      ]}
                      searchable={false}
                      value={newOptions.addressType ?? "select"}
                      onChange={(newValue) =>
                        this.updateOptionChange(
                          "addressType",
                          newValue === null ? undefined : newValue
                        )
                      }
                      placeholder="Select..."
                      size={"lg"}
                      style={{ width: 225 }}
                    />
                  </FormGroup>

                  <Form
                    layout="inline"
                    className="ccm-address-customization-form"
                  >
                    <FormGroup>
                      <Toggle
                        checked={newOptions.passAddressToComponent}
                        onChange={(checked) =>
                          this.updateOptionChange(
                            "passAddressToComponent",
                            checked
                          )
                        }
                      />
                      <ControlLabel className="inline-toggle-label">
                        Pass Address To Component
                      </ControlLabel>
                    </FormGroup>
                  </Form>

                  <Form
                    layout="inline"
                    className="ccm-address-customization-form"
                  >
                    <FormGroup>
                      <ControlLabel
                        className="ccm-address-custom-address-label"
                        style={
                          !newOptions.passAddressToComponent
                            ? { color: "lightgray" }
                            : {}
                        }
                      >
                        Line1
                      </ControlLabel>
                      <FormControl
                        disabled={!newOptions.passAddressToComponent}
                        value={newOptions.address.line1}
                        onChange={(value) =>
                          this.updateAddressToPass("line1", value)
                        }
                        name="line1"
                      />
                    </FormGroup>
                  </Form>

                  <Form
                    layout="inline"
                    className="ccm-address-customization-form"
                  >
                    <FormGroup>
                      <ControlLabel
                        className="ccm-address-custom-address-label"
                        style={
                          !newOptions.passAddressToComponent
                            ? { color: "lightgray" }
                            : {}
                        }
                      >
                        City
                      </ControlLabel>
                      <FormControl
                        disabled={!newOptions.passAddressToComponent}
                        value={newOptions.address.city}
                        onChange={(value) =>
                          this.updateAddressToPass("city", value)
                        }
                        name="city"
                      />
                    </FormGroup>
                  </Form>

                  <Form
                    layout="inline"
                    className="ccm-address-customization-form"
                  >
                    <FormGroup>
                      <ControlLabel
                        className="ccm-address-custom-address-label"
                        style={
                          !newOptions.passAddressToComponent
                            ? { color: "lightgray" }
                            : {}
                        }
                      >
                        State
                      </ControlLabel>
                      <FormControl
                        disabled={!newOptions.passAddressToComponent}
                        value={newOptions.address.state}
                        onChange={(value) =>
                          this.updateAddressToPass("state", value)
                        }
                        name="state"
                      />
                    </FormGroup>
                  </Form>

                  <Form
                    layout="inline"
                    className="ccm-address-customization-form"
                  >
                    <FormGroup>
                      <ControlLabel
                        className="ccm-address-custom-address-label"
                        style={
                          !newOptions.passAddressToComponent
                            ? { color: "lightgray" }
                            : {}
                        }
                      >
                        Zip
                      </ControlLabel>
                      <FormControl
                        disabled={!newOptions.passAddressToComponent}
                        value={newOptions.address.zip}
                        onChange={(value) =>
                          this.updateAddressToPass("zip", value)
                        }
                        name="zip"
                      />
                    </FormGroup>
                  </Form>

                  <Form
                    layout="inline"
                    className="custom-address-input-form ccm-address-clear-custom-address"
                  >
                    <Button
                      disabled={!newOptions.passAddressToComponent}
                      onClick={() => this.handleClearAddress()}
                    >
                      Clear Address
                    </Button>
                  </Form>

                  <Form
                    layout="inline"
                    className="ccm-address-customization-form"
                  >
                    <FormGroup>
                      <Toggle
                        disabled={
                          !(
                            newOptions.passAddressToComponent &&
                            this.checkIfAddressHasChanged(newOptions.address)
                          )
                        }
                        checked={newOptions.checkForValidAddressUponMount}
                        onChange={(checked) =>
                          this.updateOptionChange(
                            "checkForValidAddressUponMount",
                            checked
                          )
                        }
                      />
                      <ControlLabel
                        className="inline-toggle-label"
                        style={
                          !(
                            newOptions.passAddressToComponent &&
                            this.checkIfAddressHasChanged(newOptions.address)
                          )
                            ? { color: "lightgray" }
                            : {}
                        }
                      >
                        Check For Valid Address Upon Mount
                      </ControlLabel>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>

              <Row>
                <Col xs={5}>
                  <Button
                    loading={refreshingComponent}
                    appearance="primary"
                    onClick={() => this.handleRefreshComponent()}
                  >
                    Refresh Component
                    {!_.isEqual(this.state.newOptions, this.state.options) &&
                      "*"}
                  </Button>
                </Col>
                <Col xs={5}>
                  <Button
                    disabled={_.isEqual(
                      this.state.newOptions,
                      initialState.newOptions
                    )}
                    loading={refreshingComponent}
                    appearance="primary"
                    onClick={() => this.handleResetOptions()}
                  >
                    Reset Options
                  </Button>
                </Col>
                <Col>
                  <h3>{temporaryMessage}</h3>
                </Col>
              </Row>
              <hr />
            </div>
          </Collapse>
        </Grid>
        {!refreshingComponent && (
          <div>
            <AddressPicker
              // Required Props
              muleApiUrl={process.env.REACT_APP_MULE_API_URL}
              muleClientId={process.env.REACT_APP_MULE_API_CLIENT_ID}
              muleClientSecret={process.env.REACT_APP_MULE_API_CLIENT_SECRET}
              onAddressCollected={(address) => this.onAddressCollected(address)}
              onAddressInvalidated={() => this.onAddressInvalidated()}
              // Optional Props
              address={options.address}
              checkForValidAddressUponMount={
                options.checkForValidAddressUponMount
              }
              componentDisabled={options.componentDisabled}
              includeGeocodeData={options.includeGeocodeData}
              onStatesFetched={(fetchedStates) =>
                this.setState({ fetchedStates })
              }
              style={options.applyCustomStyle ? styleOverride : standardStyle}
              showProcessingMessages={options.showProcessingMessages}
              showRequiredAsterisks={options.showRequiredAsterisks}
              statesOverride={fetchedStates}
              title={options.customTitle}
              addressType={options.addressType}
            />
            {displayInfo && (
              <div className="ccm-address-collected">
                {addressCollected && addressValidated === true && (
                  <p>
                    <b>
                      <i>Address Collected</i>
                    </b>{" "}
                    <br />
                    {addressCollected.line1} <br />
                    {`${addressCollected.city}, ${addressCollected.state} ${addressCollected.zip}`}{" "}
                    <br />
                  </p>
                )}
                {addressCollected &&
                  addressCollected.latitude &&
                  addressCollected.longitude && (
                    <p>
                      <b>
                        <i>Latitude + Longitude</i>
                      </b>{" "}
                      <br />
                      <i>
                        {addressCollected.latitude}º,{" "}
                        {addressCollected.longitude}º
                      </i>
                    </p>
                  )}
                {addressValidated === false && (
                  <p>
                    <b>
                      <i>Address Invalidated</i>
                    </b>
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const initialState = {
  refreshingComponent: false,
  options: {
    passAddressToComponent: false,
    address: {
      line1: "",
      city: "",
      state: "",
      zip: "",
    },
    checkForValidAddressUponMount: false,
    componentDisabled: false,
    includeGeocodeData: false,
    applyCustomStyle: false,
    showProcessingMessages: false,
    showRequiredAsterisks: false,
    displayCustomTitle: false,
    customTitle: "",
  },
  newOptions: {
    passAddressToComponent: false,
    address: {
      line1: "",
      city: "",
      state: "",
      zip: "",
    },
    checkForValidAddressUponMount: false,
    componentDisabled: false,
    includeGeocodeData: false,
    applyCustomStyle: false,
    showProcessingMessages: false,
    showRequiredAsterisks: false,
    displayCustomTitle: false,
    customTitle: "",
    addressType: undefined,
  },
  displayInfo: false,
  addressValidated: null,
  addressCollected: null,
  generalInputStyle: "",
  // showCustomizationSection: false,
  showCustomizationSection: true,
  temporaryMessage: "",
  fetchedStates: null,
};
