import React, {Component} from 'react';
import {
  Grid,
  Row,
  Table,
} from 'rsuite';
import ReactModal from 'react-modal';
import { PricingCalculator } from '@ccm-innovation/pricing-components';
import './index.scss';
const { Column, HeaderCell, Cell } = Table;

export default class PricingCalculatorDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      primaryApplicantPlans: [],
      secondaryApplicantPlans: [],
      tertiaryApplicantPlans: [],
    }
  }

  formatMonies(plans) {
    if (plans.length > 0) {
      const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })

      const singlePlan = plans.length === 1;

      plans.forEach(plan => {
        plan.ahp = currencyFormatter.format(plan.ahp)
        plan.totalAmount = currencyFormatter.format(plan.totalAmount)
        if (!singlePlan) plan.hdTotalAmount = currencyFormatter.format(plan.hdTotalAmount)
      });
    }

    return plans;
  }

  handlePlanOptionsFetched(options) {
    this.setState({
      modalIsOpen: true,
      primaryApplicantPlans: this.formatMonies(options.primaryApplicant),
      secondaryApplicantPlans: this.formatMonies(options.secondaryApplicant),
      tertiaryApplicantPlans: this.formatMonies(options.tertiaryApplicant),
    })
  }

  closeModal() {
    this.setState({ 
      modalIsOpen: false,
      primaryApplicantPlans: [],
      secondaryApplicantPlans: [],
      tertiaryApplicantPlans: [],
    })
  }
  
  renderPlanTable(plans) {
    return (
      <Table
        width={"75%"}
        height={plans.length === 1 ? 90 : 350}
        data={plans}
        style={{ margin: "auto" }}
        >
        <Column
          flexGrow={1}
          align="center" >
          <HeaderCell>Annual Household Portion</HeaderCell>
          <Cell dataKey="ahp" />
        </Column>
        <Column
          flexGrow={1}
          align="center" >
          <HeaderCell>Standard Monthly Share</HeaderCell>
          <Cell dataKey="totalAmount" />
        </Column>
        {
          plans.length > 1 &&
          <Column
            flexGrow={1}
            align="center" >
            <HeaderCell>Healthy Monthly Share</HeaderCell>
            <Cell dataKey="hdTotalAmount" />
          </Column>
        }
      </Table>
    )
  }

  render() {
    const {
      modalIsOpen,
      primaryApplicantPlans,
      secondaryApplicantPlans,
      tertiaryApplicantPlans,
    } = this.state;

    return (
      <div className='ccm-pricing-calculator-demo-wrapper'>
        <Grid className='ccm-pricing-calculator-main-grid'>
          <Row>
            <h1>Pricing Calculator</h1>
            <br/>
          </Row>

          <Row>
            <PricingCalculator
              muleApiUrl={process.env.REACT_APP_MULE_API_URL}
              muleClientId={process.env.REACT_APP_MULE_API_CLIENT_ID}
              muleClientSecret={process.env.REACT_APP_MULE_API_CLIENT_SECRET}
              onPlanOptionsFetched={options => this.handlePlanOptionsFetched(options)}
              debugMode={false}
            />
          </Row>
          
          <ReactModal
            isOpen={ modalIsOpen }
            style={ { content : { height : '800px'} } }>
            <button onClick={() => this.closeModal()}>Close</button>
            <Row style={{ textAlign: "center" }}>
              <h3>{`PRIMARY Plan${primaryApplicantPlans.length === 1 ? "" : " Options"}`}</h3>
              { this.renderPlanTable(primaryApplicantPlans) }
            </Row>
            {
              secondaryApplicantPlans.length > 0 &&
              <Row style={{ textAlign: "center" }}>
                  <h3>{`SECONDARY Plan${secondaryApplicantPlans.length === 1 ? "" : " Options"}`}</h3>
                  { this.renderPlanTable(secondaryApplicantPlans) }
              </Row>
            }
            {
              tertiaryApplicantPlans.length > 0 &&
              <Row style={{ textAlign: "center" }}>
                <h3>TERTIARY Plan Options</h3>
                { this.renderPlanTable(tertiaryApplicantPlans) }
              </Row>
            }
          </ReactModal>
        </Grid>
      </div>
    )
  }
}
