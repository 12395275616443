import React from 'react';
import { ClaimsDashboard } from '@ccm-innovation/pricing-components';

import './index.scss';

const ClaimsDashboardDemo = () => (
  <div className="home">
    <h2>Claims Dashboard</h2>

    <hr />

    <ClaimsDashboard />
  </div>
);

export default ClaimsDashboardDemo;
