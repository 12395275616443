import React from 'react'
import {
  Grid,
  Row,
  Col,
  Button,
  Animation,
  Form,
  FormControl,
  FormGroup,
  Toggle,
  ControlLabel
} from 'rsuite'
import "./index.scss"
import { StatePicker } from '@ccm-innovation/address-components';
const { Collapse } = Animation;

const StatePickerDemo = props => {
  const [selectedState, setSelectedState] = React.useState(null);
  const [showFullStateNames, setShowFullStateNames] = React.useState(false);
  const [componentDisabled, setComponentDisabled] = React.useState(false);
  const [defaultOptionText, setDefaultOptionText] = React.useState("State");
  const [showCustomizationSection, toggleShowCustomizationSection] = React.useState(false);

  const resetState = () => {
    setSelectedState(null);
    setShowFullStateNames(false);
    setComponentDisabled(false);
    setDefaultOptionText("State");
  }
    
  return (
    <div className='ccm-state-picker-demo-wrapper'>
      <Grid className='ccm-state-picker-main-grid'>
        <Row>
          <h1>State Picker</h1>
        </Row>


        <Row>
          <Button
            color="green"
            onClick={() => toggleShowCustomizationSection(!showCustomizationSection)}>
            {showCustomizationSection ? 'Hide' : 'Show'} Customization Section
          </Button>
        </Row>

        <Row>
          <Collapse in={showCustomizationSection}>
            <div className="ccm-state-picker-options" >
              <Row>
                <Col>
                  <h2>Customization Options</h2>
                </Col>
              </Row>
              <Row>
                <Form layout="inline" className="ccm-state-picker-customization-form">
                  <FormGroup>
                    <Toggle
                      checked={componentDisabled}
                      onChange={checked => setComponentDisabled(checked) } />
                    <ControlLabel className="ccm-state-picker-inline-toggle-label">Component Disabled</ControlLabel>
                  </FormGroup>
                </Form>
                <Form layout="inline" className="ccm-state-picker-customization-form">
                  <FormGroup>
                    <Toggle
                      checked={showFullStateNames}
                      onChange={checked => setShowFullStateNames(checked) } />
                    <ControlLabel className="ccm-state-picker-inline-toggle-label">Show Full State Names</ControlLabel>
                  </FormGroup>
                </Form>
                <Form layout="inline" className="ccm-state-picker-customization-form">
                    <FormGroup>
                      <ControlLabel>Custom Title</ControlLabel>
                      <FormControl 
                        value={defaultOptionText}
                        onChange={value => setDefaultOptionText(value)} 
                        name="defaultOptionText" />
                    </FormGroup>
                  </Form>
              </Row>
              <Row>
                <Button
                  appearance="primary"
                  onClick={() => resetState()} >
                  Reset Component
                </Button>
              </Row>
            </div>
          </Collapse>
        </Row>
        
        <hr />
        
        <Row>
          <Col xs={6}>
            <StatePicker
              // Required Props
              selectedState={selectedState}
              onStateSelected={state => setSelectedState(state)}
              muleApiUrl={process.env.REACT_APP_MULE_API_URL}
              muleClientId={process.env.REACT_APP_MULE_API_CLIENT_ID}
              muleClientSecret={process.env.REACT_APP_MULE_API_CLIENT_SECRET}
              // Optional Props
              defaultOptionText={defaultOptionText}
              disabled={componentDisabled}
              onStatesFetched={fetchedStates => console.log('Fetched States:', fetchedStates)}
              showFullStateNames={showFullStateNames}
              style={{
                generalInputStyle: 'state-general-input-style'
              }}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default StatePickerDemo;