import React, { useEffect, useState } from 'react';

import './index.scss';

import { MemberCards } from '@ccm-innovation/member-asset-components';
import * as MockPlans from './mock-data';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';

const MemberCardsDemo = () => {
  /********************/
  /* Member Plan Data */
  /********************/
  const [samplePayload, setSamplePayload] = useState({ plans: [] });
  useEffect(() => {
    setRefreshingComponent(false);
  }, [samplePayload]);

  /******************/
  /* Selected Plans */
  /******************/
  /** Senior Assist Plan 1 **/
  const [includeSaPlan1, setIncludeSaPlan1] = useState(false);

  /** Senior Assist Plan 2 **/
  const [includeSaPlan2, setIncludeSaPlan2] = useState(false);

  /** Medi-Share Plan **/
  const [includeMsPlan, setIncludeMsPlan] = useState(false);

  /** Medi-Share for Kids Plan **/
  const [includeMsPlanKids, setIncludeMsPlanKids] = useState(false);

  /** Co-Share Plan **/
  const [includeCsPlan, setIncludeCsPlan] = useState(false);

  /** Co-Share for Kids Plan **/
  const [includeCsPlanKids, setIncludeCsPlanKids] = useState(false);

  /** Update buttons Effects **/
  useEffect(() => {
    !includeSaPlan1 && setIncludeSaPlan2(false);

    includeSaPlan2 &&
      (includeMsPlan || includeMsPlanKids) &&
      setIncludeMsPlan(false);
    includeSaPlan2 &&
      (includeCsPlan || includeCsPlanKids) &&
      setIncludeCsPlan(false);

    includeMsPlan && includeMsPlanKids && setIncludeMsPlanKids(false);

    includeMsPlanKids && includeMsPlan && setIncludeMsPlan(false);

    includeCsPlan && includeCsPlanKids && setIncludeCsPlanKids(false);

    includeCsPlanKids && includeCsPlan && setIncludeCsPlan(false);
  }, [
    includeCsPlan,
    includeCsPlanKids,
    includeMsPlan,
    includeMsPlanKids,
    includeSaPlan1,
    includeSaPlan2,
  ]);

  /****** */
  useEffect(() => {
    const newPayload = { plans: [] };

    includeSaPlan1 && newPayload.plans.push(MockPlans.SAPlan1);
    includeSaPlan2 && newPayload.plans.push(MockPlans.SAPlan2);
    includeMsPlan && newPayload.plans.push(MockPlans.MSPlan);
    includeMsPlanKids && newPayload.plans.push(MockPlans.MSPlanKids);
    includeCsPlan && newPayload.plans.push(MockPlans.CSPlan);
    includeCsPlanKids && newPayload.plans.push(MockPlans.CSPlanKids);

    setSamplePayload(newPayload);
  }, [
    includeSaPlan1,
    includeSaPlan2,
    includeMsPlan,
    includeMsPlanKids,
    includeCsPlan,
    includeCsPlanKids,
  ]);

  const handleTogglePlan = (toggledPlan) => {
    // Update Refreshing component
    setRefreshingComponent(true);

    switch (toggledPlan) {
      case 'SA1':
        setIncludeSaPlan1(!includeSaPlan1);
        break;
      case 'SA2':
        setIncludeSaPlan2(!includeSaPlan2);
        break;
      case 'MS':
        setIncludeMsPlan(!includeMsPlan);
        break;
      case 'MS4K':
        setIncludeMsPlanKids(!includeMsPlanKids);
        break;
      case 'CS':
        setIncludeCsPlan(!includeCsPlan);
        break;
      case 'CS4K':
        setIncludeCsPlanKids(!includeCsPlanKids);
        break;
      default:
        break;
    }
  };

  const handleClearSamplePayload = () => {
    setRefreshingComponent(true);
    setIncludeSaPlan1(false);
    setIncludeSaPlan2(false);
    setIncludeMsPlan(false);
    setIncludeMsPlanKids(false);
    setIncludeCsPlan(false);
    setIncludeCsPlanKids(false);
    setSamplePayload({ plans: [] });
  };

  /************************/
  /* Refreshing Component */
  /************************/
  const [refreshingComponent, setRefreshingComponent] = useState(false);

  /*********************/
  /* Height Percentage */
  /*********************/
  const [heightPercentage, setHeightPercentage] = useState(60);
  useEffect(() => {
    // Update Refreshing component
    setRefreshingComponent(true);
  }, [heightPercentage]);

  const marks = [
    {
      value: 20,
      label: '20%',
    },
    {
      value: 30,
      label: '30%',
    },
    {
      value: 40,
      label: '40%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 60,
      label: '60%',
    },
    {
      value: 70,
      label: '70%',
    },
    {
      value: 80,
      label: '80%',
    },
    {
      value: 90,
      label: '90%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];

  useEffect(() => {
    if (refreshingComponent === true) setRefreshingComponent(false);
  }, [refreshingComponent]);

  return (
    <div className={'member-cards-demo-container'}>
      <h2>Member Cards</h2>
      <div className={'mc-options-container'}>
        <div className={'mc-plan-buttons'}>
          <Button
            variant={'contained'}
            color={includeSaPlan1 ? 'primary' : 'secondary'}
            onClick={() => handleTogglePlan('SA1')}
          >
            SA1
          </Button>
          <Button
            variant={'contained'}
            color={includeSaPlan2 ? 'primary' : 'secondary'}
            disabled={!includeSaPlan1}
            onClick={() => handleTogglePlan('SA2')}
          >
            SA2
          </Button>
          <Button
            variant={'contained'}
            color={includeMsPlan ? 'primary' : 'secondary'}
            disabled={
              (includeSaPlan1 && includeSaPlan2) ||
              includeMsPlanKids ||
              includeCsPlan ||
              includeCsPlanKids
            }
            onClick={() => handleTogglePlan('MS')}
          >
            MS
          </Button>
          <Button
            variant={'contained'}
            color={includeMsPlanKids ? 'primary' : 'secondary'}
            disabled={includeCsPlan || includeMsPlan || includeCsPlanKids}
            onClick={() => handleTogglePlan('MS4K')}
          >
            MS 4 Kids
          </Button>
          <Button
            variant={'contained'}
            color={includeCsPlan ? 'primary' : 'secondary'}
            disabled={
              (includeSaPlan1 && includeSaPlan2) ||
              includeMsPlan ||
              includeMsPlanKids ||
              includeCsPlanKids
            }
            onClick={() => handleTogglePlan('CS')}
          >
            CS
          </Button>
          <Button
            variant={'contained'}
            color={includeCsPlanKids ? 'primary' : 'secondary'}
            disabled={includeCsPlan || includeMsPlan || includeMsPlanKids}
            onClick={() => handleTogglePlan('CS4K')}
          >
            CS 4 Kids
          </Button>
        </div>
        <div className={'inline clear-member-cards-button'}>
          <Button
            variant={'contained'}
            color={'secondary'}
            disabled={samplePayload.plans.length === 0}
            onClick={handleClearSamplePayload}
          >
            Clear Sample Payload
          </Button>
        </div>
        <div>
          <Slider
            value={heightPercentage}
            aria-labelledby="discrete-slider-small-steps"
            step={5}
            min={20}
            max={100}
            valueLabelDisplay="auto"
            marks={marks}
            onChange={(e, val) => setHeightPercentage(val)}
          />
        </div>
      </div>
      <hr />
      {!refreshingComponent && (
        <MemberCards
          memberData={samplePayload}
          cardHeight={636 * (heightPercentage / 100)}
        />
      )}
    </div>
  );
};

export default MemberCardsDemo;
