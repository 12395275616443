export const standardBillsDataExample = {
  startDate: '2021-01-01',
  endDate: '2021-12-31',
  plans: [
    {
      currentPlan: false,
      program: 'MS2.0',
      programLevel: 'M2-3000',
      ahp: {
        limit: 3000,
        deducted: 136.35,
        remaining: 2863.65,
        insurancePaid: 0,
      },
      mcr: {
        limit: null,
        totalDeducted: null,
        coshareDeducted: null,
        remaining: null,
      },
      startOn: '2021-01-01',
      endOn: '2021-12-31',
      claims: [
        {
          claimId: '21620998000',
          total: 430,
          ineligible: 0,
          discounted: 262.39,
          ahpDeducted: 132.61,
          copayAmount: 35,
          outOfNetwork: 0,
          overallMemberResponsibility: 167.61,
          medishareResponsibility: 0,
          coInsAmt: null,
          sharePercentage: 0,
          dateIncurred: '2021-02-25',
          dateReceived: '2021-02-28T00:00:00',
          datePaid: null,
          providerName: '[STANDARD] SAMPLE PROVIDER #3',
          processed: false,
          claimStatus: 'Pending',
          patientFirstName: 'BILLY',
          patientLastName: 'SMITH',
          checkNumber: 2767635,
          checkPayee: 'BILLY SMITH',
          checkAmount: 545.23,
          serviceLines: [
            {
              claimDetailId: '9036394',
              benefitCode: '410',
              benefitCodeDescription: 'PHYSICAL THERAPY',
              cptCode: '97110',
              total: 60,
              ineligible: 0,
              discounted: 26,
              ahpDeducted: 0,
              providerFee: 34,
              coinsuranceAmount: null,
              sharePercentage: 0,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 34,
              reasonCodes: [
                {
                  ClaimDetailID: '9036394',
                  code: '77',
                  description: 'PHCS DISCOUNT APPLIED',
                },
                {
                  ClaimDetailID: '9036394',
                  code: 'F1',
                  description: 'PROVIDER FEE',
                },
              ],
            },
            {
              claimDetailId: '16719144',
              benefitCode: '410',
              benefitCodeDescription: 'X-RAY',
              cptCode: '97014',
              total: 40,
              ineligible: 40,
              discounted: 0,
              ahpDeducted: 0,
              copaymentAmount: 0,
              sharePercentage: 0,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 40,
              reasonCodes: [
                {
                  ClaimDetailID: '16719144',
                  code: 'ZJ',
                  description: 'UTILIZATION',
                },
              ],
            },
            {
              claimDetailId: '14108625',
              benefitCode: '410',
              benefitCodeDescription: 'DIAGNOSTICS',
              cptCode: '97140',
              total: 65,
              ineligible: 0,
              discounted: 31,
              ahpDeducted: 33,
              copaymentAmount: 1,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 34,
              reasonCodes: [
                {
                  ClaimDetailID: '14108625',
                  code: '77',
                  description: 'PHCS DISCOUNT APPLIED',
                },
                {
                  ClaimDetailID: '14108625',
                  code: 'F1',
                  description: 'PROVIDER FEE',
                },
              ],
            },
          ],
        },
        {
          claimId: '220C6348400',
          total: 214,
          ineligible: 0,
          discounted: 82.98,
          ahpDeducted: 96.02,
          copayAmount: 35,
          outOfNetwork: 0,
          overallMemberResponsibility: 131.02,
          medishareResponsibility: 0,
          coInsAmt: null,
          sharePercentage: 0,
          dateIncurred: '2021-01-18',
          dateReceived: '2021-01-21T00:00:00',
          datePaid: '2021-01-30T00:00:00',
          providerName: '[STANDARD] SAMPLE PROVIDER #2',
          processed: true,
          claimStatus: 'Processed',
          patientFirstName: 'BILLY',
          patientLastName: 'SMITH',
          checkNumber: 2767635,
          checkPayee: 'BILLY SMITH',
          checkAmount: 545.23,
          serviceLines: [
            {
              claimDetailId: '9036394',
              benefitCode: '410',
              benefitCodeDescription: 'PHYSICAL THERAPY',
              cptCode: '97110',
              total: 60,
              ineligible: 0,
              discounted: 26,
              ahpDeducted: 0,
              providerFee: 34,
              coinsuranceAmount: null,
              sharePercentage: 0,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 34,
              reasonCodes: [
                {
                  ClaimDetailID: '9036394',
                  code: '77',
                  description: 'PHCS DISCOUNT APPLIED',
                },
                {
                  ClaimDetailID: '9036394',
                  code: 'F1',
                  description: 'PROVIDER FEE',
                },
              ],
            },
            {
              claimDetailId: '16719144',
              benefitCode: '410',
              benefitCodeDescription: 'X-RAY',
              cptCode: '97014',
              total: 40,
              ineligible: 40,
              discounted: 0,
              ahpDeducted: 0,
              copaymentAmount: 0,
              sharePercentage: 0,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 40,
              reasonCodes: [
                {
                  ClaimDetailID: '16719144',
                  code: 'ZJ',
                  description: 'UTILIZATION',
                },
              ],
            },
            {
              claimDetailId: '14108625',
              benefitCode: '410',
              benefitCodeDescription: 'DIAGNOSTICS',
              cptCode: '97140',
              total: 65,
              ineligible: 0,
              discounted: 31,
              ahpDeducted: 33,
              copaymentAmount: 1,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 34,
              reasonCodes: [
                {
                  ClaimDetailID: '14108625',
                  code: '77',
                  description: 'PHCS DISCOUNT APPLIED',
                },
                {
                  ClaimDetailID: '14108625',
                  code: 'F1',
                  description: 'PROVIDER FEE',
                },
              ],
            },
          ],
        },
        {
          claimId: '21624385700',
          total: 364.89,
          ineligible: 0,
          discounted: 283.33,
          ahpDeducted: 81.56,
          copayAmount: 0,
          outOfNetwork: 0,
          overallMemberResponsibility: 81.56,
          medishareResponsibility: 0,
          coInsAmt: null,
          sharePercentage: 0,
          dateIncurred: '2021-01-04',
          dateReceived: '2021-01-10T00:00:00',
          datePaid: '2021-01-14T00:00:00',
          providerName: '[STANDARD] SAMPLE PROVIDER #1',
          processed: true,
          claimStatus: 'Processed',
          patientFirstName: 'BILLY',
          patientLastName: 'SMITH',
          checkNumber: 2767635,
          checkPayee: 'BILLY SMITH',
          checkAmount: 545.23,
          serviceLines: [
            {
              claimDetailId: '9036394',
              benefitCode: '410',
              benefitCodeDescription: 'PHYSICAL THERAPY',
              cptCode: '97110',
              total: 60,
              ineligible: 0,
              discounted: 26,
              ahpDeducted: 0,
              providerFee: 34,
              coinsuranceAmount: null,
              sharePercentage: 0,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 34,
              reasonCodes: [
                {
                  ClaimDetailID: '9036394',
                  code: '77',
                  description: 'PHCS DISCOUNT APPLIED',
                },
                {
                  ClaimDetailID: '9036394',
                  code: 'F1',
                  description: 'PROVIDER FEE',
                },
              ],
            },
            {
              claimDetailId: '16719144',
              benefitCode: '410',
              benefitCodeDescription: 'X-RAY',
              cptCode: '97014',
              total: 40,
              ineligible: 40,
              discounted: 0,
              ahpDeducted: 0,
              copaymentAmount: 0,
              sharePercentage: 0,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 40,
              reasonCodes: [
                {
                  ClaimDetailID: '16719144',
                  code: 'ZJ',
                  description: 'UTILIZATION',
                },
              ],
            },
            {
              claimDetailId: '14108625',
              benefitCode: '410',
              benefitCodeDescription: 'DIAGNOSTICS',
              cptCode: '97140',
              total: 65,
              ineligible: 0,
              discounted: 31,
              ahpDeducted: 33,
              copaymentAmount: 1,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 34,
              reasonCodes: [
                {
                  ClaimDetailID: '14108625',
                  code: '77',
                  description: 'PHCS DISCOUNT APPLIED',
                },
                {
                  ClaimDetailID: '14108625',
                  code: 'F1',
                  description: 'PROVIDER FEE',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const coShareBillsDataExample = {
  startDate: '2021-01-01',
  endDate: '2021-12-31',
  plans: [
    {
      currentPlan: false,
      program: 'MS2.0',
      programLevel: 'M2-3000',
      ahp: {
        limit: 3000,
        deducted: 136.35,
        remaining: 2863.65,
        insurancePaid: 0,
      },
      mcr: {
        limit: null,
        totalDeducted: null,
        coshareDeducted: null,
        remaining: null,
      },
      startOn: '2021-01-01',
      endOn: '2021-12-31',
      claims: [
        {
          claimId: '21620998000',
          total: 430,
          ineligible: 0,
          discounted: 262.39,
          ahpDeducted: 132.61,
          copayAmount: 35,
          outOfNetwork: 0,
          overallMemberResponsibility: 167.61,
          medishareResponsibility: 0,
          coInsAmt: 0,
          sharePercentage: 0,
          dateIncurred: '2021-02-25',
          dateReceived: '2021-02-28T00:00:00',
          datePaid: null,
          providerName: '[CO-SHARE] SAMPLE PROVIDER #3',
          processed: false,
          claimStatus: 'Pending',
          patientFirstName: 'BILLY',
          patientLastName: 'SMITH',
          checkNumber: 2767635,
          checkPayee: 'BILLY SMITH',
          checkAmount: 545.23,
          serviceLines: [
            {
              claimDetailId: '9036394',
              benefitCode: '410',
              benefitCodeDescription: 'PHYSICAL THERAPY',
              cptCode: '97110',
              total: 60,
              ineligible: 0,
              discounted: 26,
              ahpDeducted: 0,
              providerFee: 34,
              coinsuranceAmount: null,
              sharePercentage: 0,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 34,
              reasonCodes: [
                {
                  ClaimDetailID: '9036394',
                  code: '77',
                  description: 'PHCS DISCOUNT APPLIED',
                },
                {
                  ClaimDetailID: '9036394',
                  code: 'F1',
                  description: 'PROVIDER FEE',
                },
              ],
            },
            {
              claimDetailId: '16719144',
              benefitCode: '410',
              benefitCodeDescription: 'X-RAY',
              cptCode: '97014',
              total: 40,
              ineligible: 40,
              discounted: 0,
              ahpDeducted: 0,
              copaymentAmount: 0,
              sharePercentage: 0,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 40,
              reasonCodes: [
                {
                  ClaimDetailID: '16719144',
                  code: 'ZJ',
                  description: 'UTILIZATION',
                },
              ],
            },
            {
              claimDetailId: '14108625',
              benefitCode: '410',
              benefitCodeDescription: 'DIAGNOSTICS',
              cptCode: '97140',
              total: 65,
              ineligible: 0,
              discounted: 31,
              ahpDeducted: 33,
              copaymentAmount: 1,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 34,
              reasonCodes: [
                {
                  ClaimDetailID: '14108625',
                  code: '77',
                  description: 'PHCS DISCOUNT APPLIED',
                },
                {
                  ClaimDetailID: '14108625',
                  code: 'F1',
                  description: 'PROVIDER FEE',
                },
              ],
            },
          ],
        },
        {
          claimId: '220C6348400',
          total: 214,
          ineligible: 0,
          discounted: 82.98,
          ahpDeducted: 96.02,
          copayAmount: 35,
          outOfNetwork: 0,
          overallMemberResponsibility: 131.02,
          medishareResponsibility: 0,
          coInsAmt: 0,
          sharePercentage: 0,
          dateIncurred: '2021-01-18',
          dateReceived: '2021-01-21T00:00:00',
          datePaid: '2021-01-30T00:00:00',
          providerName: '[CO-SHARE] SAMPLE PROVIDER #2',
          processed: true,
          claimStatus: 'Processed',
          patientFirstName: 'BILLY',
          patientLastName: 'SMITH',
          checkNumber: 2767635,
          checkPayee: 'BILLY SMITH',
          checkAmount: 545.23,
          serviceLines: [
            {
              claimDetailId: '9036394',
              benefitCode: '410',
              benefitCodeDescription: 'PHYSICAL THERAPY',
              cptCode: '97110',
              total: 60,
              ineligible: 0,
              discounted: 26,
              ahpDeducted: 0,
              providerFee: 34,
              coinsuranceAmount: null,
              sharePercentage: 0,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 34,
              reasonCodes: [
                {
                  ClaimDetailID: '9036394',
                  code: '77',
                  description: 'PHCS DISCOUNT APPLIED',
                },
                {
                  ClaimDetailID: '9036394',
                  code: 'F1',
                  description: 'PROVIDER FEE',
                },
              ],
            },
            {
              claimDetailId: '16719144',
              benefitCode: '410',
              benefitCodeDescription: 'X-RAY',
              cptCode: '97014',
              total: 40,
              ineligible: 40,
              discounted: 0,
              ahpDeducted: 0,
              copaymentAmount: 0,
              sharePercentage: 0,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 40,
              reasonCodes: [
                {
                  ClaimDetailID: '16719144',
                  code: 'ZJ',
                  description: 'UTILIZATION',
                },
              ],
            },
            {
              claimDetailId: '14108625',
              benefitCode: '410',
              benefitCodeDescription: 'DIAGNOSTICS',
              cptCode: '97140',
              total: 65,
              ineligible: 0,
              discounted: 31,
              ahpDeducted: 33,
              copaymentAmount: 1,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 34,
              reasonCodes: [
                {
                  ClaimDetailID: '14108625',
                  code: '77',
                  description: 'PHCS DISCOUNT APPLIED',
                },
                {
                  ClaimDetailID: '14108625',
                  code: 'F1',
                  description: 'PROVIDER FEE',
                },
              ],
            },
          ],
        },
        {
          claimId: '21624385700',
          total: 364.89,
          ineligible: 0,
          discounted: 283.33,
          ahpDeducted: 81.56,
          copayAmount: 0,
          outOfNetwork: 0,
          overallMemberResponsibility: 81.56,
          medishareResponsibility: 0,
          coInsAmt: 0,
          sharePercentage: 0,
          dateIncurred: '2021-01-04',
          dateReceived: '2021-01-10T00:00:00',
          datePaid: '2021-01-20T00:00:00',
          providerName: '[CO-SHARE] SAMPLE PROVIDER #1',
          processed: true,
          claimStatus: 'Processed',
          patientFirstName: 'BILLY',
          patientLastName: 'SMITH',
          checkNumber: 2767635,
          checkPayee: 'BILLY SMITH',
          checkAmount: 545.23,
          serviceLines: [
            {
              claimDetailId: '9036394',
              benefitCode: '410',
              benefitCodeDescription: 'PHYSICAL THERAPY',
              cptCode: '97110',
              total: 60,
              ineligible: 0,
              discounted: 26,
              ahpDeducted: 0,
              providerFee: 34,
              coinsuranceAmount: null,
              sharePercentage: 0,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 34,
              reasonCodes: [
                {
                  ClaimDetailID: '9036394',
                  code: '77',
                  description: 'PHCS DISCOUNT APPLIED',
                },
                {
                  ClaimDetailID: '9036394',
                  code: 'F1',
                  description: 'PROVIDER FEE',
                },
              ],
            },
            {
              claimDetailId: '16719144',
              benefitCode: '410',
              benefitCodeDescription: 'X-RAY',
              cptCode: '97014',
              total: 40,
              ineligible: 40,
              discounted: 0,
              ahpDeducted: 0,
              copaymentAmount: 0,
              sharePercentage: 0,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 40,
              reasonCodes: [
                {
                  ClaimDetailID: '16719144',
                  code: 'ZJ',
                  description: 'UTILIZATION',
                },
              ],
            },
            {
              claimDetailId: '14108625',
              benefitCode: '410',
              benefitCodeDescription: 'DIAGNOSTICS',
              cptCode: '97140',
              total: 65,
              ineligible: 0,
              discounted: 31,
              ahpDeducted: 33,
              copaymentAmount: 1,
              outOfNetwork: 0,
              mediShareResponsibility: 0,
              overallMemberResponsibility: 34,
              reasonCodes: [
                {
                  ClaimDetailID: '14108625',
                  code: '77',
                  description: 'PHCS DISCOUNT APPLIED',
                },
                {
                  ClaimDetailID: '14108625',
                  code: 'F1',
                  description: 'PROVIDER FEE',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const totalsData = {
  chargeAmounts: 2559.67,
  discounts: -511.82,
  providerFees: 340.0,
  ineligible: 474.53,
  sharedAmounts: 687.89,
  myResponsibility: 905.6,
  appliedToAhp: 1232.69,
  startDate: '2021-01-01',
  endDate: '2021-12-31',
};
