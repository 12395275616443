const SAPlan1 = {
  currentPlan: true,
  program: 'SA',
  householdId: '1302S44542',
  member: {
    name: 'Senior 1',
    netsuiteId: '130244542',
    relationship: 'Self',
    membershipStartOn: '2020-06-01',
  },
  dependents: [],
};

const SAPlan2 = {
  currentPlan: true,
  program: 'SA',
  householdId: '2454S42031',
  member: {
    name: 'Senior 2',
    netsuiteId: '245442031',
    relationship: 'Self',
    membershipStartOn: '2020-06-01',
  },
  dependents: [],
};

const MSPlan = {
  currentPlan: true,
  program: 'MS3',
  householdId: '1302H44542',
  member: {
    name: 'MS3 Hoh',
    netsuiteId: '130244542',
    relationship: 'Self',
    membershipStartOn: '2020-06-01',
  },
  dependents: [],
};

const MSPlanKids = {
  currentPlan: true,
  program: 'MS3',
  householdId: '1302G44542',
  member: {
    name: 'MS3 Guardian',
    netsuiteId: '130244542',
    relationship: 'Guardian',
    membershipStartOn: '2020-06-01',
  },
  dependents: [
    {
      name: 'MS3 Kiddo 1',
      netsuiteId: '130244248',
      relationship: 'Child',
    },
    {
      name: 'MS3 Kiddo 2',
      netsuiteId: '130244939',
      relationship: 'Child',
    },
  ],
};

const CSPlan = {
  currentPlan: true,
  program: 'CS1',
  householdId: '1302H44542',
  member: {
    name: 'CS Hoh',
    netsuiteId: '130244542',
    relationship: 'Self',
    membershipStartOn: '2020-06-01',
  },
  dependents: [
    {
      name: 'CS Kiddo Billy',
      netsuiteId: '130244241',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Bobby',
      netsuiteId: '130244242',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Timmy',
      netsuiteId: '130244243',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Tommy',
      netsuiteId: '130244244',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Barry',
      netsuiteId: '130244245',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Terry',
      netsuiteId: '130244246',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Shari',
      netsuiteId: '130244247',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Carrie',
      netsuiteId: '130244248',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Mike',
      netsuiteId: '130244249',
      relationship: 'Child',
    },
    {
      name: 'CS Spouse',
      netsuiteId: '130244939',
      relationship: 'Spouse',
    },
  ],
};

const CSPlanKids = {
  currentPlan: true,
  program: 'CS1',
  householdId: '1302G44542',
  member: {
    name: 'CS Guardian',
    netsuiteId: '130244542',
    relationship: 'Guardian',
    membershipStartOn: '2020-06-01',
  },
  dependents: [
    {
      name: 'CS Kiddo Billy',
      netsuiteId: '130244241',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Bobby',
      netsuiteId: '130244932',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Timmy',
      netsuiteId: '130244243',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Tommy',
      netsuiteId: '130244934',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Barry',
      netsuiteId: '130244245',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Terry',
      netsuiteId: '130244936',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Shari',
      netsuiteId: '130244247',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Carrie',
      netsuiteId: '130244938',
      relationship: 'Child',
    },
    {
      name: 'CS Kiddo Mike',
      netsuiteId: '130244939',
      relationship: 'Child',
    },
  ],
};

module.exports = { SAPlan1, SAPlan2, MSPlan, MSPlanKids, CSPlan, CSPlanKids };
