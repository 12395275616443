import React from 'react';
import { Link } from 'react-router-dom';

import { Navbar, Nav, Icon, Dropdown } from 'rsuite';

import CCMLogo from './ccm.png';

import 'rsuite/dist/styles/rsuite-default.css';
import './index.scss';

const Navigation = () => (
  <div className={'ccm-navigation'}>
    <Navbar>
      <Navbar.Header>
        <Link to="/" className="ccm-logo-wrapper logo">
          <img className="ccm-logo" src={CCMLogo} alt="logo" />
        </Link>
      </Navbar.Header>

      <Navbar.Body>
        <Nav>
          {/* Home nav item */}
          <NavLink to="/">Home</NavLink>

          {/* Address Components */}
          <Dropdown title="address-components">
            <DropdownNavLink
              icon={<Icon icon="info" />}
              to="/address-components"
            >
              About
            </DropdownNavLink>

            <DropdownNavLink
              icon={<Icon icon="address-book" />}
              to="/address-components/address-picker"
            >
              Address Picker
            </DropdownNavLink>

            <DropdownNavLink
              icon={<Icon icon="globe" />}
              to="/address-components/state-picker"
            >
              State Picker
            </DropdownNavLink>
          </Dropdown>

          {/* Member Asset Components */}
          <Dropdown title="member-asset-components">
            <DropdownNavLink
              icon={<Icon icon="info" />}
              to="/member-asset-components"
            >
              About
            </DropdownNavLink>

            <DropdownNavLink
              icon={<Icon icon="vcard-o" />}
              to="/member-asset-components/member-cards"
            >
              Member Cards
            </DropdownNavLink>
          </Dropdown>

          {/* Member Center React Controls */}
          <Dropdown title="member-center-react-controls">
            <DropdownNavLink
              icon={<Icon icon="info" />}
              to="/member-center-react-controls"
            >
              About
            </DropdownNavLink>

            <DropdownNavLink
              icon={<Icon icon="table" />}
              to="/member-center-react-controls/medical-spend-history"
            >
              Medical Spend History
            </DropdownNavLink>
          </Dropdown>

          {/* Pricing Components */}
          <Dropdown title="pricing-components">
            <DropdownNavLink
              icon={<Icon icon="info" />}
              to="/pricing-components"
            >
              About
            </DropdownNavLink>

            <DropdownNavLink
              icon={<Icon icon="dashboard" />}
              to="/pricing-components/claims-dashboard"
            >
              Claims Dashboard
            </DropdownNavLink>

            <DropdownNavLink
              icon={<Icon icon="calculator" />}
              to="/pricing-components/pricing-calculator"
            >
              Pricing Calculator
            </DropdownNavLink>

            <DropdownNavLink
              icon={<Icon icon="calculator" />}
              to="/pricing-components/pricing-calculator-classic"
            >
              Pricing Calculator Classic
            </DropdownNavLink>

            <DropdownNavLink
              icon={<Icon icon="table" />}
              to="/pricing-components/pricing-calculator-tiled"
            >
              Pricing Calculator Tiled
            </DropdownNavLink>

            <DropdownNavLink
              icon={<Icon icon="pie-chart" />}
              to="/pricing-components/pricing-donut"
            >
              Pricing Donut
            </DropdownNavLink>

            <DropdownNavLink
              icon={<Icon icon="table" />}
              to="/pricing-components/switch-tool-tiled"
            >
              Switch Tool Tiled
            </DropdownNavLink>

            <DropdownNavLink
              icon={<Icon icon="table" />}
              to="/pricing-components/pricing-table-classic"
            >
              Pricing Table Classic
            </DropdownNavLink>
          </Dropdown>
        </Nav>

        {/* Link to NPM */}
        <Nav pullRight>
          <Nav.Item
            icon={<Icon icon="code" />}
            href={
              'https://gitlab.com/ccm-innovation/ccm-2.0/registry/-/packages'
            }
            target="_blank"
          >
            @ccm-innovation
          </Nav.Item>
        </Nav>
      </Navbar.Body>
    </Navbar>
  </div>
);

const NavLink = (props) => (
  <Nav.Item
    componentClass={Link}
    icon={<Icon icon={props.iconName} />}
    {...props}
  />
);
const DropdownNavLink = (props) => (
  <Dropdown.Item componentClass={Link} {...props} />
);

export default Navigation;
