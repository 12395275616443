import React from 'react';
import './index.scss';
import { PricingDonut } from '@ccm-innovation/pricing-components';
import { InputNumber } from 'rsuite';

const PricingDonutDemo = (props) => {
  const BLUE = '#1A4297';
  const GREEN = '#5F7E26';
  const ORANGE = '#F8971D';
  const RED = '#B33237';
  const PURPLE = '#9932CC';

  const allDonutData = [
    {
      name: 'Value 1',
      value: 688,
      color: BLUE,
    },
    {
      name: 'Value 2',
      value: 512,
      color: GREEN,
    },
    {
      name: 'Value 3',
      value: 906,
      color: ORANGE,
    },
    {
      name: 'Value 4',
      value: 442,
      color: RED,
    },
    {
      name: 'Value 5',
      value: 315,
      color: PURPLE,
    },
  ];

  const [refreshingComponent, setRefreshingComponent] = React.useState(false);

  const [chartWidth, setChartWidth] = React.useState(325);
  const [donutData, setDonutData] = React.useState(allDonutData.slice(0, 3));
  const [centerTextFontSize, setCenterTextFontSize] = React.useState(null);
  const [labelNameFontSize, setLabelNameFontSize] = React.useState(null);
  const [labelValueFontSize, setLabelValueFontSize] = React.useState(null);
  const [showAnimation, setShowAnimation] = React.useState(true);
  const [titleFontSize, setTitleFontSize] = React.useState(null);
  const [numberOfDataPoints, setNumberOfDataPoints] = React.useState(null);

  const handleChangeChartWidth = (value) => {
    setChartWidth(parseInt(value));
    setRefreshingComponent(true);
  };

  const handleChangeTitleFontSize = (value) => {
    setTitleFontSize(parseInt(value));
    setRefreshingComponent(true);
  };

  const handleChangeNumberOfDataPoints = (value) => {
    console.log(value);
    setNumberOfDataPoints(value);
    setDonutData(allDonutData.slice(0, value));
  };

  React.useEffect(() => {
    refreshingComponent && setRefreshingComponent(false);
  }, [refreshingComponent]);

  const data = {
    header: 'Sample Pricing Donut Chart',
    centerText: {
      name: 'Sample Total',
      value: donutData.reduce(function (prev, cur) {
        return prev + cur.value;
      }, 0),
    },
    donutData: donutData,
  };

  return (
    <div className="pricing-donut-demo">
      <h1>Pricing Donut</h1>
      <div className="pricing-donut-demo__props">
        <div>
          <label
            className="pricing-donut-demo__props-label"
            htmlFor="chart-width"
          >
            Chart Width:
          </label>
          <InputNumber
            id="center-text-font-size"
            step={10}
            min={150}
            value={chartWidth}
            onChange={handleChangeChartWidth}
          />
        </div>
        <div>
          <label
            className="pricing-donut-demo__props-label"
            htmlFor="center-text-font-size"
          >
            Center Text Font Size:
          </label>
          <InputNumber
            id="center-text-font-size"
            step={2}
            min={12}
            value={centerTextFontSize}
            onChange={setCenterTextFontSize}
          />
        </div>
        <div>
          <label
            className="pricing-donut-demo__props-label"
            htmlFor="label-name-font-size"
          >
            Label Name Font Size:
          </label>
          <InputNumber
            id="label-name-font-size"
            step={2}
            min={8}
            value={labelNameFontSize}
            onChange={setLabelNameFontSize}
          />
        </div>
      </div>
      <div className="pricing-donut-demo__props">
        <div>
          <label
            className="pricing-donut-demo__props-label"
            htmlFor="label-value-font-size"
          >
            Label Value Font Size:
          </label>
          <InputNumber
            id="label-value-font-size"
            step={2}
            min={8}
            value={labelValueFontSize}
            onChange={setLabelValueFontSize}
          />
        </div>
        <div>
          <label
            className="pricing-donut-demo__props-label"
            htmlFor="show-animtation"
          >
            Show Animation:
          </label>
          <input
            type="checkbox"
            id="show-animation"
            checked={showAnimation}
            onChange={() => setShowAnimation(!showAnimation)}
          />
        </div>
        <div>
          <label
            className="pricing-donut-demo__props-label"
            htmlFor="title-font-size"
          >
            Title Font Size:
          </label>
          <InputNumber
            id="title-font-size"
            step={2}
            min={12}
            value={titleFontSize}
            onChange={handleChangeTitleFontSize}
          />
        </div>
        <div>
          <label
            className="switch-tool-tiled-demo__props-label"
            htmlFor="number-of-data-points"
          >
            Number of Data Points:
          </label>
          <select
            className="switch-tool-tiled-demo__props-control"
            id="number-of-data-points"
            value={numberOfDataPoints}
            onChange={(e) => handleChangeNumberOfDataPoints(e.target.value)}
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
          </select>
        </div>
      </div>
      <hr />
      <div className="pricing-donut-demo__component">
        {!refreshingComponent && (
          <PricingDonut
            data={data}
            chartWidth={chartWidth}
            centerTextFontSize={centerTextFontSize}
            labelNameFontSize={labelNameFontSize}
            labelValueFontSize={labelValueFontSize}
            showAnimation={showAnimation}
            titleFontSize={titleFontSize}
          />
        )}
      </div>
    </div>
  );
};

export default PricingDonutDemo;
