import React, { useState } from "react";
import "./index.scss";
import { PricingCalculatorTiled } from "@ccm-innovation/pricing-components";

export const defaultFilteredPrograms = {
  select: {
    MS3: {
      select: {
        "MS3-12K": true,
        "MS3-9K": true,
        "MS3-6K": true,
        "MS3-3K": true,
      },
    },
    CS1: {
      select: {
        "CS1-9K": true,
        "CS1-6K": true,
        "CS1-3K": true,
      },
    },
    MV1: {
      select: {
        "MV1-500": true,
        "MV1-1K": true,
      },
    },
    SA: {
      select: {
        "SA 1250": true,
      },
    },
    MS65: {
      select: {
        "MS65-500": true,
      },
    },
  },
};

const PricingCalculatorTiledDemo = (props) => {
  const [language, setLanguage] = useState("en-US");
  const [showDiscount, setShowDiscount] = useState(false);
  const [showMDFDiscounts, setShowMDFDiscounts] = useState(true);
  const [overrideMDFDisclaimer, setOverrideMDFDisclaimer] = useState(false);
  const [showGuidestoneDiscounts, setShowGuidestoneDiscounts] = useState(false);
  const [forApplication, setForApplication] = useState(false);
  const [showFullStateNames, setShowFullStateNames] = useState(true);
  const [linkDirectPrimaryCare, setLinkDpc] = useState("");
  const [linkTextDirectPrimaryCare, setLinkTextDpc] = useState("");
  const [applicationFeeNoteFooter, setApplicationFeeNoteFooter] = useState("");
  const [guidestoneDiscountDisclaimer, setGuidestoneDiscountDisclaimer] =
    useState(null);
  const [linkAnnualPhysicals, setLinkAp] = useState("");
  const [editState, setEditState] = useState(true);
  const [quoteParams, setQuoteParams] = useState({});
  const [selectedProgram, setSelectedProgram] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [timeAt, setTimeAt] = useState(undefined);
  const [hideMsValue, setHideMsValue] = useState(false);
  const [enableMs65, setEnableMs65] = useState(false);
  const [enableComplete, setEnableComplete] = useState(false);
  const [quotingParamsCallback, setQuotingParamsCallback] = useState(false);
  const [programSelectCallback, setProgramSelectCallback] = useState(false);
  const [editStateCallback, setEditStateCallback] = useState(false);
  const [filterPrograms, setfilterPrograms] = useState({});

  const handleLoadingStateChange = (newState) => {
    console.log("loading: ", newState);
    setIsLoading(newState);
  };

  const handleFilterProgramsChange = (newState) => {
    setfilterPrograms(newState);
  };

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };
  const handleChangeShowDiscount = (event) => {
    setShowDiscount(event.target.checked);
  };
  const handleChangeShowMDFDiscounts = (event) => {
    setShowMDFDiscounts(event.target.checked);
  };
  const handleChangeOverrideMDFDisclaimer = (event) => {
    setOverrideMDFDisclaimer(event.target.checked);
  };
  const handleChangeShowGuidestoneDiscounts = (event) => {
    setShowGuidestoneDiscounts(event.target.checked);
  };
  const handleChangeForApplication = (event) => {
    setForApplication(event.target.checked);
  };
  const handleChangeGuidestoneDiscountDisclaimer = (event) => {
    setGuidestoneDiscountDisclaimer(event.target.value);
  };
  const handleChangeTimeAt = (event) => {
    setTimeAt(event.target.value);
  };
  const handleChangeShowFullStateNames = (event) => {
    setShowFullStateNames(event.target.checked);
  };
  const handleChangeLinkDpc = (event) => {
    setLinkDpc(event.target.value);
  };
  const handleChangeLinkTextDpc = (event) => {
    setLinkTextDpc(event.target.value);
  };
  const handleChangeApplicationFeeNoteFooter = (event) => {
    setApplicationFeeNoteFooter(event.target.value);
  };
  const handleChangeLinkAp = (event) => {
    setLinkAp(event.target.value);
  };
  const handleChangeQuotingParamsCallback = (event) => {
    setQuotingParamsCallback(event.target.checked);
  };
  const handleChangeProgramSelectCallback = (event) => {
    setProgramSelectCallback(event.target.checked);
  };
  const handleChangeEditStateCallback = (event) => {
    setEditStateCallback(event.target.checked);
  };
  const handleEditStateChange = (isEditing) => {
    setEditState(isEditing);
  };
  const handleParamChange = (data) => {
    setQuoteParams(data);
  };
  const handleProgramSelect = (data) => {
    setSelectedProgram(data);
  };
  const handleChangeHideMsValue = (event) => {
    setHideMsValue(event.target.checked);
  };
  const handleChangeEnableMs65 = (event) => {
    setEnableMs65(event.target.checked);
  };
  const handleChangeEnableComplete = (event) => {
    setEnableComplete(event.target.checked);
  };

  const externalQuoteButton = document.querySelector("#externalQuoteButton");

  return (
    <div className="pricing-calculator-tiled-demo">
      <h1>Pricing Calculator Tiled</h1>

      <div className="pricing-calculator-tiled-demo__container">
        <div className="pricing-calculator-tiled-demo__column">
          <div>
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="language"
            >
              Language:
            </label>
            <select
              className="pricing-calculator-tiled-demo__props-control"
              id="language"
              value={language}
              onChange={handleChangeLanguage}
            >
              <option value="en-US">English</option>
              <option value="es-US">Española</option>
              <option value="ru-RU">Русский</option>
              <option value="ps-PS">Pseudo</option>
            </select>
          </div>
          <div>
            <label>Filtered Programs:</label>
            <div>
              {Object.keys(defaultFilteredPrograms.select).map(
                (programName) => (
                  <div key={programName}>
                    <label className="pricing-calculator-tiled-demo__props-label">
                      {programName}:
                      <input
                        type="checkbox"
                        className="pricing-calculator-tiled-demo__props-control"
                        checked={filterPrograms.select?.[programName] === true}
                        onChange={(e) =>
                          handleFilterProgramsChange({
                            select: {
                              ...filterPrograms.select,
                              [programName]: e.target.checked,
                            },
                          })
                        }
                      />
                    </label>
                    {defaultFilteredPrograms.select[programName].select && (
                      <div>
                        {Object.keys(
                          defaultFilteredPrograms.select[programName].select
                        ).map((levelName) => (
                          <label key={levelName}>
                            {levelName}:
                            <input
                              type="checkbox"
                              checked={
                                filterPrograms.select?.[programName]?.select?.[
                                  levelName
                                ] === true
                              }
                              onChange={(e) =>
                                handleFilterProgramsChange({
                                  select: {
                                    ...filterPrograms.select,
                                    [programName]: {
                                      select: {
                                        ...filterPrograms.select?.[programName]
                                          ?.select,
                                        [levelName]: e.target.checked,
                                      },
                                    },
                                  },
                                })
                              }
                            />
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
          <div>
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="forApplication"
            >
              Target start date:
            </label>
            <input
              type="date"
              className="pricing-calculator-tiled-demo__props-control"
              id="start-date"
              onChange={handleChangeTimeAt}
            />
          </div>

          <div>
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="linkDirectPrimaryCare"
            >
              Override Direct Primary Care Link:
            </label>
            <input
              type="text"
              id="linkDirectPrimaryCare"
              className="pricing-calculator-tiled-demo__props-control"
              value={linkDirectPrimaryCare}
              onChange={handleChangeLinkDpc}
            />
          </div>
          <div>
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="linkAnnualPhysicals"
            >
              Override Annual Physicals Link:
            </label>
            <input
              type="text"
              id="linkAnnualPhysicals"
              className="pricing-calculator-tiled-demo__props-control"
              value={linkAnnualPhysicals}
              onChange={handleChangeLinkAp}
            />
          </div>
          <div>
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="linkTextDirectPrimaryCare"
            >
              Override Direct Primary Care Link Text:
            </label>
            <input
              type="text"
              id="linkTextDirectPrimaryCare"
              className="pricing-calculator-tiled-demo__props-control"
              value={linkTextDirectPrimaryCare}
              onChange={handleChangeLinkTextDpc}
            />
          </div>
          <div>
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="applicationFeeNoteFooter"
            >
              Override One-Time Fees Text:
            </label>
            <input
              type="text"
              id="applicationFeeNoteFooter"
              className="pricing-calculator-tiled-demo__props-control"
              value={applicationFeeNoteFooter}
              onChange={handleChangeApplicationFeeNoteFooter}
            />
          </div>
          <div>
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="applicationFeeNoteFooter"
            >
              Guidestone Disclaimer Text:
            </label>
            <input
              type="text"
              id="applicationFeeNoteFooter"
              className="pricing-calculator-tiled-demo__props-control"
              value={guidestoneDiscountDisclaimer}
              onChange={handleChangeGuidestoneDiscountDisclaimer}
            />
          </div>

          <div>
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="loadingOrProcessing"
            >
              Component is loading/processing?
            </label>
            <span className="pricing-calculator-tiled-demo__props-control">
              {isLoading ? "Yes" : "No"}
            </span>
          </div>
        </div>
        <div className="pricing-calculator-tiled-demo__column">
          <div>
            <input
              type="checkbox"
              className="pricing-calculator-tiled-demo__props-control"
              id="showDiscount"
              checked={showDiscount}
              onChange={handleChangeShowDiscount}
            />
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="showDiscount"
            >
              Show Discount
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="pricing-calculator-tiled-demo__props-control"
              id="showMDFDiscount"
              checked={showMDFDiscounts}
              onChange={handleChangeShowMDFDiscounts}
            />
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="showMDFDiscounts"
            >
              Show MDF Discounts
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="pricing-calculator-tiled-demo__props-control"
              id="overrideMDFDisclaimer"
              checked={overrideMDFDisclaimer}
              onChange={handleChangeOverrideMDFDisclaimer}
            />
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="overrideMDFDisclaimer"
            >
              Override MDF Disclaimer
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="pricing-calculator-tiled-demo__props-control"
              id="showMDFDiscounts"
              checked={showGuidestoneDiscounts}
              onChange={handleChangeShowGuidestoneDiscounts}
            />
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="showGuidestoneDiscounts"
            >
              Show Guidestone Discounts
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="pricing-calculator-tiled-demo__props-control"
              id="hideMsValue"
              checked={hideMsValue}
              onChange={handleChangeHideMsValue}
            />
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="hideMsValue"
            >
              Remove MV1 from payload
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="pricing-calculator-tiled-demo__props-control"
              id="enableMs65"
              checked={enableMs65}
              onChange={handleChangeEnableMs65}
            />
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="enableMs65"
            >
              Enable MS65
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="pricing-calculator-tiled-demo__props-control"
              id="enableComplete"
              checked={enableComplete}
              onChange={handleChangeEnableComplete}
            />
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="enableComplete"
            >
              Enable MS Complete
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="pricing-calculator-tiled-demo__props-control"
              id="showFullStateNames"
              checked={showFullStateNames}
              onChange={handleChangeShowFullStateNames}
            />
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="showFullStateNames"
            >
              Show Full State Names
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="pricing-calculator-tiled-demo__props-control"
              id="forApplication"
              checked={forApplication}
              onChange={handleChangeForApplication}
            />
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="forApplication"
            >
              For Application (part of application process)
            </label>
          </div>

          <div>
            <input
              type="checkbox"
              className="pricing-calculator-tiled-demo__props-control"
              id="callbackQuotingParams"
              checked={quotingParamsCallback}
              onChange={handleChangeQuotingParamsCallback}
            />
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="callbackQuotingParams"
            >
              Callback for quoting params
            </label>
          </div>
          {quotingParamsCallback && (
            <div>
              <label
                className="pricing-calculator-tiled-demo__props-label"
                htmlFor="quoteParams"
              >
                Quoting Parameters
              </label>
              <p className="pricing-calculator-tiled-demo__props-control">
                {JSON.stringify(quoteParams)}
              </p>
            </div>
          )}

          <div>
            <input
              type="checkbox"
              className="pricing-calculator-tiled-demo__props-control"
              id="callbackProgramSelect"
              checked={programSelectCallback}
              onChange={handleChangeProgramSelectCallback}
            />
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="callbackProgramSelect"
            >
              Callback for program selection
            </label>
          </div>
          {programSelectCallback && (
            <div>
              <label
                className="pricing-calculator-tiled-demo__props-label"
                htmlFor="selectedProgram"
              >
                Selected Program
              </label>
              <p className="pricing-calculator-tiled-demo__props-control">
                {JSON.stringify(selectedProgram)}
              </p>
            </div>
          )}

          <div>
            <input
              type="checkbox"
              className="pricing-calculator-tiled-demo__props-control"
              id="callbackEditState"
              checked={editStateCallback}
              onChange={handleChangeEditStateCallback}
            />
            <label
              className="pricing-calculator-tiled-demo__props-label"
              htmlFor="callbackEditState"
            >
              Callback for edit state change
            </label>
          </div>
          {editStateCallback && (
            <div>
              <label
                className="pricing-calculator-tiled-demo__props-label"
                htmlFor="editState"
              >
                In Edit State?
              </label>
              <p className="pricing-calculator-tiled-demo__props-control">
                {editState ? "Yes" : "No"}
              </p>
            </div>
          )}
        </div>
      </div>

      <hr></hr>

      <div className="pricing-calculator-tiled-demo__component">
        <PricingCalculatorTiled
          muleApiUrl={process.env.REACT_APP_MULE_API_URL}
          muleClientId={process.env.REACT_APP_MULE_API_CLIENT_ID}
          muleClientSecret={process.env.REACT_APP_MULE_API_CLIENT_SECRET}
          language={language}
          showDiscount={showDiscount}
          showMDFDiscounts={showMDFDiscounts}
          overrideMDFDisclaimer={overrideMDFDisclaimer}
          showGuidestoneDiscounts={showGuidestoneDiscounts}
          showFullStateNames={showFullStateNames}
          linkDirectPrimaryCare={linkDirectPrimaryCare}
          linkTextDirectPrimaryCare={linkTextDirectPrimaryCare}
          applicationFeeNoteFooter={applicationFeeNoteFooter}
          guidestoneDiscountDisclaimer={guidestoneDiscountDisclaimer}
          linkAnnualPhysicals={linkAnnualPhysicals}
          forApplication={forApplication}
          timeAt={timeAt}
          originalQuoteDate={timeAt}
          lookoutDateCount={5}
          actionButtonURL={"http://ccm-apply-dev.herokuapp.com/?page=apply"}
          externalQuoteButton={externalQuoteButton}
          hideMsValue={hideMsValue}
          enableMs65={enableMs65}
          enableComplete={enableComplete}
          handleEditStateChange={
            editStateCallback ? handleEditStateChange : undefined
          }
          handleParamChange={
            quotingParamsCallback ? handleParamChange : undefined
          }
          handleProgramSelect={
            programSelectCallback ? handleProgramSelect : undefined
          }
          handleLoadingStateChange={handleLoadingStateChange}
          rootElement="#root"
          filterPrograms={filterPrograms}
        />
      </div>
    </div>
  );
};

export default PricingCalculatorTiledDemo;
