import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import './App.scss';

import Navigation from './pages/navigation';
import Home from './pages/home';
// Address Components
import AddressComponentsAbout from './pages/address-components/about';
import StatePicker from './pages/address-components/state-picker';
import AddressPicker from './pages/address-components/address-picker';
// Member Asset Components
import MemberAssetComponentsAbout from './pages/member-asset-components/about';
import MemberCardsDemo from './pages/member-asset-components/member-cards';
// TODO: Remove MSH from member-asset-components -> import MedicalSpendHistoryDemo from './pages/member-asset-components/medical-spend-history';
// Member Asset Components
import MemberCenterReactControlsAbout from './pages/member-center-react-controls/about';
import MedicalSpendHistoryDemo from './pages/member-center-react-controls/medical-spend-history';
// Pricing Componetns
import ClaimsDashboardDemo from './pages/pricing-components/claims-dashboard';
import PricingComponentsAbout from './pages/pricing-components/about';
import PricingCalculatorDemo from './pages/pricing-components/pricing-calculator';
import PricingCalculatorClassicDemo from './pages/pricing-components/pricing-calculator-classic';
import PricingCalculatorTiledDemo from './pages/pricing-components/pricing-calculator-tiled';
import PricingDonutDemo from './pages/pricing-components/pricing-donut';
import PricingTableClassicDemo from './pages/pricing-components/pricing-table-classic';
import SwitchToolTiledDemo from './pages/pricing-components/switch-tool-tiled';

const App = () => (
  <Router>
    <div className="app-container">
      <Navigation />
      <div className="TestAppContainer">
        <Switch>
          <Route exact path="/" component={Home} />

          {/* Address Components */}
          <Route
            exact
            path="/address-components"
            component={AddressComponentsAbout}
          />
          <Route
            exact
            path="/address-components/state-picker"
            component={StatePicker}
          />
          <Route
            exact
            path="/address-components/address-picker"
            component={AddressPicker}
          />

          {/* Member Asset Components */}
          <Route
            exact
            path="/member-asset-components"
            component={MemberAssetComponentsAbout}
          />
          <Route
            exact
            path="/member-asset-components/member-cards"
            component={MemberCardsDemo}
          />

          {/* Member Asset Components */}
          <Route
            exact
            path="/member-center-react-controls"
            component={MemberCenterReactControlsAbout}
          />
          <Route
            exact
            path="/member-center-react-controls/medical-spend-history"
            component={MedicalSpendHistoryDemo}
          />

          {/* Pricing Components */}
          <Route
            exact
            path="/pricing-components"
            component={PricingComponentsAbout}
          />
          <Route
            exact
            path="/pricing-components/claims-dashboard"
            component={ClaimsDashboardDemo}
          />
          <Route
            exact
            path="/pricing-components/pricing-calculator"
            component={PricingCalculatorDemo}
          />
          <Route
            exact
            path="/pricing-components/pricing-calculator-classic"
            component={PricingCalculatorClassicDemo}
          />
          <Route
            exact
            path="/pricing-components/pricing-donut"
            component={PricingDonutDemo}
          />
          <Route
            exact
            path="/pricing-components/pricing-table-classic"
            component={PricingTableClassicDemo}
          />
          <Route
            exact
            path="/pricing-components/pricing-calculator-tiled"
            component={PricingCalculatorTiledDemo}
          />
          <Route
            exact
            path="/pricing-components/switch-tool-tiled"
            component={SwitchToolTiledDemo}
          />

          {/* Default Route */}
          <Redirect path="*" to="/" />
        </Switch>
      </div>
    </div>
  </Router>
);

export default App;
