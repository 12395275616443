import React from 'react'
import {
  Grid,
  Row,
  Col,
  Button,
  Animation,
  Form,
  FormGroup,
  Toggle,
  ControlLabel
} from 'rsuite'
import './index.scss'
import { PricingTableClassic } from '@ccm-innovation/pricing-components';
const { Collapse } = Animation;

const PricingTableClassicDemo  = props => {
  const initialState = {
    // Optional props
    sortAhpAscending: false,
    renderHealthyMonthlyShare: true,
  }
  
  // Hardcoded programs to display
  const planOptions = {"programs":[{"name":"MS3","programLevels":[{"name":"MS3-12K","ahpAmount":12000,"totalAmount":109,"healthyDiscountTotalAmount":106,"products":[{"name":"Admin Fee","amount":36},{"name":"Health Discount","amount":-3},{"name":"Monthly Share Portion","amount":73}]},{"name":"MS3-9K","ahpAmount":9000,"totalAmount":148,"healthyDiscountTotalAmount":144,"products":[{"name":"Admin Fee","amount":39},{"name":"Health Discount","amount":-4},{"name":"Monthly Share Portion","amount":109}]},{"name":"MS3-6K","ahpAmount":6000,"totalAmount":183,"healthyDiscountTotalAmount":178,"products":[{"name":"Admin Fee","amount":42},{"name":"Health Discount","amount":-5},{"name":"Monthly Share Portion","amount":141}]},{"name":"MS3-3K","ahpAmount":3000,"totalAmount":242,"healthyDiscountTotalAmount":236,"products":[{"name":"Admin Fee","amount":46},{"name":"Health Discount","amount":-6},{"name":"Monthly Share Portion","amount":196}]}]}]};

  const [sortAhpAscending, setSortAhpAscending] = React.useState(initialState.sortAhpAscending);
  const [renderHealthyMonthlyShare, setRenderHealthyMonthlyShare] = React.useState(initialState.renderHealthyMonthlyShare);
  const [showCustomizationSection, setShowCustomizationSection] = React.useState(false);

  const resetState = () => {
    setSortAhpAscending(initialState.sortAhpAscending);
    setRenderHealthyMonthlyShare(initialState.renderHealthyMonthlyShare);
  }

  return (
    <div className='pricing-calculator-classic-demo-wrapper'>
      <Grid className='pricing-calculator-classic-main-grid'>
        <Row>
          <h1>Pricing Table Classic</h1>
        </Row>

        <br />

        <Row>
          <Button
            color="green"
            onClick={() => setShowCustomizationSection(!showCustomizationSection)}>
            {showCustomizationSection ? 'Hide' : 'Show'} Customization Section
          </Button>
        </Row>

        <Row>
          <Collapse in={showCustomizationSection}>
            <div className="pricing-table-classic-options" >
              <br />
              <Row>
                <Col>
                  <h2>Customization Options</h2>
                </Col>
              </Row>
              <Row>
                <Form layout="inline" className="pricing-table-classic-customization-form">
                  <FormGroup>
                    <Toggle
                      checked={sortAhpAscending}
                      onChange={checked => setSortAhpAscending(checked) } />
                    <ControlLabel className="pricing-table-classic-inline-toggle-label">Sort AHP Ascending</ControlLabel>
                  </FormGroup>
                </Form>
                <Form layout="inline" className="pricing-table-classic-customization-form">
                  <FormGroup>
                    <Toggle
                      checked={renderHealthyMonthlyShare}
                      onChange={checked => setRenderHealthyMonthlyShare(checked) } />
                    <ControlLabel className="pricing-table-classic-inline-toggle-label">Render Healthy Monthly Share</ControlLabel>
                  </FormGroup>
                </Form>
              </Row>
              <Row>
                <Button
                  appearance="primary"
                  onClick={() => resetState()} >
                  Reset Component
                </Button>
              </Row>
            </div>
          </Collapse>
        </Row>

        <hr />

        <Row>
          <Col className='pricing-calculator-classic-component'>
            <PricingTableClassic
              planOptions={planOptions}
              sortAhpAscending={sortAhpAscending}
              renderHealthyMonthlyShare={renderHealthyMonthlyShare}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default PricingTableClassicDemo;