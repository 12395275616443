const switchOptions1 = {
  Message: "Ok",
  Programs: [
    {
      Name: "M2",
      ProgramLevels: [
        {
          Name: "AHP 5500",
          Current: true,
          SwitchDate: null,
          SwitchFeeAmount: 0,
          AhpAmount: 5500,
          McrAmount: null,
          TotalAmount: 425.36,
          HealthyDiscountTotalAmount: 372.36,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 57,
            },
            {
              Name: "Health Discount",
              Amount: -53,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 368.36,
            },
          ],
          Options: [],
        },
      ],
    },
    {
      Name: "MS3",
      ProgramLevels: [
        {
          Name: "MS3-12K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 0,
          AhpAmount: 12000,
          McrAmount: null,
          TotalAmount: 239,
          HealthyDiscountTotalAmount: 233,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 46,
            },
            {
              Name: "Health Discount",
              Amount: -6,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 193,
            },
          ],
          Options: [
            {
              Slug: "DPC",
              Description: "Direct Primary Care",
            },
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "MS3-9K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 0,
          AhpAmount: 9000,
          McrAmount: null,
          TotalAmount: 344,
          HealthyDiscountTotalAmount: 335,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 54,
            },
            {
              Name: "Health Discount",
              Amount: -9,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 290,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "MS3-6K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 0,
          AhpAmount: 6000,
          McrAmount: null,
          TotalAmount: 436,
          HealthyDiscountTotalAmount: 424,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 61,
            },
            {
              Name: "Health Discount",
              Amount: -12,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 375,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "MS3-3K",
          Current: false,
          SwitchDate: "2021-08-01",
          SwitchFeeAmount: 0,
          AhpAmount: 3000,
          McrAmount: null,
          TotalAmount: 595,
          HealthyDiscountTotalAmount: 579,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 73,
            },
            {
              Name: "Health Discount",
              Amount: -16,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 522,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
      ],
    },
    {
      Name: "CS1",
      ProgramLevels: [
        {
          Name: "CS1-6K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 0,
          AhpAmount: 6000,
          McrAmount: 10000,
          TotalAmount: 376,
          HealthyDiscountTotalAmount: 366,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 57,
            },
            {
              Name: "Health Discount",
              Amount: -10,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 319,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "CS1-3K",
          Current: false,
          SwitchDate: "2021-08-01",
          SwitchFeeAmount: 0,
          AhpAmount: 3000,
          McrAmount: 10000,
          TotalAmount: 482,
          HealthyDiscountTotalAmount: 469,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 65,
            },
            {
              Name: "Health Discount",
              Amount: -13,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 417,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
      ],
    },
  ],
};

const switchOptions2 = {
  Message: "Ok",
  Programs: [
    {
      Name: "MS3",
      ProgramLevels: [
        {
          Name: "MS3-12K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 12000,
          McrAmount: null,
          TotalAmount: 163,
          HealthyDiscountTotalAmount: 159,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 40,
            },
            {
              Name: "Health Discount",
              Amount: -4,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 123,
            },
          ],
          Options: [
            {
              Slug: "DPC",
              Description: "Direct Primary Care",
            },
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "MS3-9K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 9000,
          McrAmount: null,
          TotalAmount: 230,
          HealthyDiscountTotalAmount: 224,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 45,
            },
            {
              Name: "Health Discount",
              Amount: -6,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 185,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "MS3-6K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 6000,
          McrAmount: null,
          TotalAmount: 290,
          HealthyDiscountTotalAmount: 282,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 50,
            },
            {
              Name: "Health Discount",
              Amount: -8,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 240,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "MS3-3K",
          Current: true,
          SwitchDate: null,
          SwitchFeeAmount: 0,
          AhpAmount: 3000,
          McrAmount: null,
          TotalAmount: 391,
          HealthyDiscountTotalAmount: 380,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 58,
            },
            {
              Name: "Health Discount",
              Amount: -11,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 333,
            },
          ],
          Options: [],
        },
      ],
    },
    {
      Name: "CS1",
      ProgramLevels: [
        {
          Name: "CS1-6K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 6000,
          McrAmount: 10000,
          TotalAmount: 251,
          HealthyDiscountTotalAmount: 244,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 47,
            },
            {
              Name: "Health Discount",
              Amount: -7,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 204,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "CS1-3K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 3000,
          McrAmount: 10000,
          TotalAmount: 319,
          HealthyDiscountTotalAmount: 310,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 52,
            },
            {
              Name: "Health Discount",
              Amount: -9,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 267,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
      ],
    },
  ],
};

const switchOptions3 = {
  Message: "Ok",
  Programs: [
    {
      Name: "MS3",
      ProgramLevels: [
        {
          Name: "MS3-12K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 12000,
          McrAmount: null,
          TotalAmount: 90,
          HealthyDiscountTotalAmount: 88,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 35,
            },
            {
              Name: "Health Discount",
              Amount: -2,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 55,
            },
          ],
          Options: [
            {
              Slug: "DPC",
              Description: "Direct Primary Care",
            },
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "MS3-9K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 9000,
          McrAmount: null,
          TotalAmount: 119,
          HealthyDiscountTotalAmount: 116,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 37,
            },
            {
              Name: "Health Discount",
              Amount: -3,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 82,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "MS3-6K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 6000,
          McrAmount: null,
          TotalAmount: 146,
          HealthyDiscountTotalAmount: 142,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 39,
            },
            {
              Name: "Health Discount",
              Amount: -4,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 107,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "MS3-3K",
          Current: true,
          SwitchDate: null,
          SwitchFeeAmount: 0,
          AhpAmount: 3000,
          McrAmount: null,
          TotalAmount: 173,
          HealthyDiscountTotalAmount: 169,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 41,
            },
            {
              Name: "Health Discount",
              Amount: -4,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 132,
            },
          ],
          Options: [],
        },
      ],
    },
    {
      Name: "CS1",
      ProgramLevels: [
        {
          Name: "CS1-6K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 6000,
          McrAmount: 10000,
          TotalAmount: 129,
          HealthyDiscountTotalAmount: 126,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 38,
            },
            {
              Name: "Health Discount",
              Amount: -3,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 91,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "CS1-3K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 3000,
          McrAmount: 10000,
          TotalAmount: 159,
          HealthyDiscountTotalAmount: 155,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 40,
            },
            {
              Name: "Health Discount",
              Amount: -4,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 119,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
      ],
    },
  ],
};

const switchOptions4 = {
  Message: "Ok",
  Programs: [
    {
      Name: "MS3",
      ProgramLevels: [
        {
          Name: "MS3-12K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 12000,
          McrAmount: null,
          TotalAmount: 416,
          HealthyDiscountTotalAmount: 405,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 60,
            },
            {
              Name: "Health Discount",
              Amount: -11,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 356,
            },
          ],
          Options: [
            {
              Slug: "DPC",
              Description: "Direct Primary Care",
            },
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "MS3-9K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 9000,
          McrAmount: null,
          TotalAmount: 609,
          HealthyDiscountTotalAmount: 592,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 75,
            },
            {
              Name: "Health Discount",
              Amount: -17,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 534,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "MS3-6K",
          Current: true,
          SwitchDate: null,
          SwitchFeeAmount: 0,
          AhpAmount: 6000,
          McrAmount: null,
          TotalAmount: 778,
          HealthyDiscountTotalAmount: 757,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 88,
            },
            {
              Name: "Health Discount",
              Amount: -21,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 690,
            },
          ],
          Options: [],
        },
        {
          Name: "MS3-3K",
          Current: false,
          SwitchDate: "2021-08-01",
          SwitchFeeAmount: 75,
          AhpAmount: 3000,
          McrAmount: null,
          TotalAmount: 1011,
          HealthyDiscountTotalAmount: 982,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 110,
            },
            {
              Name: "Health Discount",
              Amount: -29,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 901,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
      ],
    },
    {
      Name: "CS1",
      ProgramLevels: [
        {
          Name: "CS1-6K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 6000,
          McrAmount: 10000,
          TotalAmount: 666,
          HealthyDiscountTotalAmount: 648,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 79,
            },
            {
              Name: "Health Discount",
              Amount: -18,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 587,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "CS1-3K",
          Current: false,
          SwitchDate: "2021-08-01",
          SwitchFeeAmount: 75,
          AhpAmount: 3000,
          McrAmount: 10000,
          TotalAmount: 815,
          HealthyDiscountTotalAmount: 791,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 94,
            },
            {
              Name: "Health Discount",
              Amount: -24,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 721,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
      ],
    },
  ],
};

const switchOptions5 = {
  Message: "Ok",
  Programs: [
    {
      Name: "MS3",
      ProgramLevels: [
        {
          Name: "MS3-12K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 12000,
          McrAmount: null,
          TotalAmount: 80,
          HealthyDiscountTotalAmount: null,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 34,
            },
            {
              Name: "Health Discount",
              Amount: null,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 46,
            },
          ],
          Options: [
            {
              Slug: "DPC",
              Description: "Direct Primary Care",
            },
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "MS3-9K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 9000,
          McrAmount: null,
          TotalAmount: 106,
          HealthyDiscountTotalAmount: null,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 36,
            },
            {
              Name: "Health Discount",
              Amount: null,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 70,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "MS3-6K",
          Current: true,
          SwitchDate: null,
          SwitchFeeAmount: 0,
          AhpAmount: 6000,
          McrAmount: null,
          TotalAmount: 128,
          HealthyDiscountTotalAmount: null,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 38,
            },
            {
              Name: "Health Discount",
              Amount: null,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 90,
            },
          ],
          Options: [],
        },
        {
          Name: "MS3-3K",
          Current: false,
          SwitchDate: "2021-08-01",
          SwitchFeeAmount: 75,
          AhpAmount: 3000,
          McrAmount: null,
          TotalAmount: 165,
          HealthyDiscountTotalAmount: null,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 40,
            },
            {
              Name: "Health Discount",
              Amount: null,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 125,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
      ],
    },
    {
      Name: "CS1",
      ProgramLevels: [
        {
          Name: "CS1-6K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 6000,
          McrAmount: 10000,
          TotalAmount: 113,
          HealthyDiscountTotalAmount: null,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 36,
            },
            {
              Name: "Health Discount",
              Amount: null,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 77,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "CS1-3K",
          Current: false,
          SwitchDate: "2021-08-01",
          SwitchFeeAmount: 75,
          AhpAmount: 3000,
          McrAmount: 10000,
          TotalAmount: 138,
          HealthyDiscountTotalAmount: null,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 38,
            },
            {
              Name: "Health Discount",
              Amount: null,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 100,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
      ],
    },
  ],
};

const switchOptions6 = {
  Message: "Ok",
  Programs: [
    {
      Name: "MS3",
      ProgramLevels: [
        {
          Name: "MS3-12K",
          Current: false,
          SwitchDate: "2021-06-01",
          SwitchFeeAmount: 75,
          AhpAmount: 12000,
          McrAmount: null,
          TotalAmount: 324,
          HealthyDiscountTotalAmount: 315,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 53,
            },
            {
              Name: "Health Discount",
              Amount: -9,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 271,
            },
          ],
          Options: [
            {
              Slug: "DPC",
              Description: "Direct Primary Care",
            },
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
        {
          Name: "MS3-9K",
          Current: true,
          SwitchDate: null,
          SwitchFeeAmount: 0,
          AhpAmount: 9000,
          McrAmount: null,
          TotalAmount: 450,
          HealthyDiscountTotalAmount: 438,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 62,
            },
            {
              Name: "Health Discount",
              Amount: -12,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 388,
            },
          ],
          Options: [],
        },
        {
          Name: "MS3-6K",
          Current: false,
          SwitchDate: "2021-08-01",
          SwitchFeeAmount: 75,
          AhpAmount: 6000,
          McrAmount: null,
          TotalAmount: 599,
          HealthyDiscountTotalAmount: 583,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 74,
            },
            {
              Name: "Health Discount",
              Amount: -16,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 525,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
      ],
    },
    {
      Name: "CS1",
      ProgramLevels: [
        {
          Name: "CS1-6K",
          Current: false,
          SwitchDate: "2021-08-01",
          SwitchFeeAmount: 75,
          AhpAmount: 6000,
          McrAmount: 10000,
          TotalAmount: 514,
          HealthyDiscountTotalAmount: 500,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 67,
            },
            {
              Name: "Health Discount",
              Amount: -14,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 447,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
      ],
    },
  ],
};

const switchOptions7 = {
  Message: "Ok",
  Programs: [
    {
      Name: "MS3",
      ProgramLevels: [
        {
          Name: "MS3-12K",
          Current: true,
          SwitchDate: null,
          SwitchFeeAmount: 0,
          AhpAmount: 12000,
          McrAmount: null,
          TotalAmount: 121,
          HealthyDiscountTotalAmount: 118,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 37,
            },
            {
              Name: "Health Discount",
              Amount: -3,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 84,
            },
          ],
          Options: [],
        },
        {
          Name: "MS3-9K",
          Current: false,
          SwitchDate: "2021-08-01",
          SwitchFeeAmount: 75,
          AhpAmount: 9000,
          McrAmount: null,
          TotalAmount: 171,
          HealthyDiscountTotalAmount: 167,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 41,
            },
            {
              Name: "Health Discount",
              Amount: -4,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 130,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
      ],
    },
  ],
};

const switchOptions8 = {
  Message: "Ok",
  Programs: [
    {
      Name: "MS3",
      ProgramLevels: [
        {
          Name: "MS3-12K",
          Current: true,
          SwitchDate: null,
          SwitchFeeAmount: 0,
          AhpAmount: 12000,
          McrAmount: null,
          TotalAmount: 312,
          HealthyDiscountTotalAmount: 304,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 52,
            },
            {
              Name: "Health Discount",
              Amount: -8,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 260,
            },
          ],
          Options: [],
        },
        {
          Name: "MS3-9K",
          Current: false,
          SwitchDate: "2021-08-01",
          SwitchFeeAmount: 75,
          AhpAmount: 9000,
          McrAmount: null,
          TotalAmount: 456,
          HealthyDiscountTotalAmount: 444,
          Products: [
            {
              Name: "Admin Fee",
              Amount: 63,
            },
            {
              Name: "Health Discount",
              Amount: -12,
            },
            {
              Name: "Monthly Share Portion",
              Amount: 393,
            },
          ],
          Options: [
            {
              Slug: "APLL",
              Description: "Annual Physicals w/Labs (Limited)",
            },
          ],
        },
      ],
    },
  ],
};

module.exports = { switchOptions1, switchOptions2, switchOptions3, switchOptions4, switchOptions5, switchOptions6, switchOptions7, switchOptions8 };
